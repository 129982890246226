import React, {useEffect, useState } from 'react'
import exportFromJSON from 'export-from-json'
import { Col, Row, Table, Form, InputGroup, FormControl, Button } from 'react-bootstrap'
import axios from 'axios'
import { NotificationManager } from 'react-notifications';
import moment from 'moment';

export default function SolicitudContacto({solicitudes}) {
  const pagos = [{
            cliente: '165432',
            nombreCliente: 'Jose Garcia',
            telefonoCliente: '98767656',
            correoCliente: 'josegarcia@gsolid.com',
            consultaCliente: '',
            fecha: '10/02/2022',

            
        },
        {
            cliente: '165433',
            nombreCliente: 'Adam Smith',
            telefonoCliente: '98767356',
            correoCliente: 'adamsmith@gsolid.com',
            consultaCliente: '',
            fecha: '10/02/2022'
        },
        {
            cliente: '165434',
            nombreCliente: 'Kevin Conrath',
            telefonoCliente: '33767656',
            correoCliente: 'kevinco@gsolid.com',
            consultaCliente: '',
            fecha: '10/02/2022'
        },
        {
            cliente: '165435',
            nombreCliente: 'Juan Ordoñez',
            telefonoCliente: '48767656',
            correoCliente: 'juanord@gsolid.com',
            consultaCliente: '',
            fecha: '10/02/2022'
        },
        {
            cliente: '165436',
            nombreCliente: 'Walter Blanco',
            telefonoCliente: '68767656',
            correoCliente: 'wblanco@gsolid.com',
            consultaCliente: '',
            fecha: '10/02/2022'
        },
        {
            cliente: '165437',
            nombreCliente: 'Elias Lara',
            telefonoCliente: '28767656',
            correoCliente: 'eliaslara@gsolid.com',
            consultaCliente: '',
            fecha: '10/02/2022'
        },
        {
            cliente: '165438',
            nombreCliente: 'Byron Meza',
            telefonoCliente: '31767656',
            correoCliente: 'bmeza@gsolid.com',
            consultaCliente: '',
            fecha: '10/02/2022'
        }
    ]
  const [listaSolicitudes,setListaSolicitudes] = useState([])


  // async function exportXLSX() {    
  //   const fileName = 'estadoCuenta'
  //   const exportType = exportFromJSON.types.xls

  //   exportFromJSON({
  //     data:pagos, fileName, exportType,
  //     beforeTableEncode: (rows,cols) => {        
  //       return rows
  //     },
  //   })

  // }

  

  useEffect(() => {
    // getSolicitudes()
    if (solicitudes){
      setListaSolicitudes(solicitudes)
    }
    
  }, [solicitudes])



  return (
    <>
      <Table striped bordered hover size="sm" responsive>
        <thead style={{ color: 'white', backgroundColor: '#00295E' }}>
          <tr>
            <th>Nombre Completo</th>
            <th>Teléfono</th>
            <th>Consulta</th>
            <th>Fecha</th>
          </tr>
        </thead>
        <tbody>
          {listaSolicitudes.map((item, index) => (
            <tr key={index}>
              <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>{item.nombre} {item.apellido}</td>
              <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>{item.telefono}</td>
              <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>{item.consulta}</td>
              <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>{moment(new Date(item.createdAt)).format('DD/MM/YYYY')}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  )
}
