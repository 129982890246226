import React, { useState, useEffect } from 'react';
import exportFromJSON from 'export-from-json'
import { Col, Row, Table, Form, InputGroup, FormControl, Button } from 'react-bootstrap';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import axios from 'axios';
import moment from 'moment'
import { NotificationManager } from 'react-notifications';

export default function Reembolso({pagos,loadPagos}) {

  const [pagoActual,setPagoActual] = useState("")

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getCountry = (moneda)=>{
    if(moneda === "CRC"){
      return "CR"
    }else if (moneda === "GTQ"){
      return "GT"
    }else if(moneda === "HND"){
      return "HN"
    }else if(moneda === "NIO"){
      return "NI"
    }
  }


  async function refund(){
    console.log("Pago Actual: ",pagoActual)
    // console.log(idPago)
    var tempTrn = "47850b3d-213d-48a7-8a9a-df5e280935fc"
    var tempOrder = "CCHN-0.8606964945577549"
    try{

      const data= {
        TransactionIdentifier:pagoActual.OriginalTransaction__c,
        ExternalIdentifier:pagoActual.OrderIndentifier__c,
        TotalAmount:pagoActual.ImportePagado__c,
        CurrencyCode:pagoActual.ClaveMoneda__c,
        pais:getCountry(pagoActual.ClaveMoneda__c)

      }
      const response = await axios.post(process.env.REACT_APP_BACKEND_URL + "pay/refund",data)
      console.log("response BAC Refund: ",response)

      const dataBac = response.data.data
      // const dataBac = {Approved:true,ExternalIdentifier:"CCHN-0.31725664582308255"}
     
      if(dataBac.Approved){
        const bearer = localStorage.getItem('bearer')
        var dataTransaccion = {

          TransactionType__c: 5,
         
          FechaContabilizacion__c: moment(new Date()).format("YYYY-MM-DD"),
          FechaDocumento__c: moment(new Date()).format("YYYY-MM-DD"),
          OrderIndentifier__c: dataBac.ExternalIdentifier,
          OriginalTransaction__c:pagoActual.OriginalTransaction__c,
          Payment_Identifier__c:pagoActual.Payment_Identifier__c,
          Pago__c:pagoActual.Id

        }

        const responseSale = await axios.post(process.env.REACT_APP_BACKEND_URL + "crm/refund/", dataTransaccion,{
          headers: {
            Authorization: "Bearer " + "user.token",
            CRMtoken: bearer
          }
        })
        console.log("Refund Salesforce",responseSale)
        if (responseSale.data.success){
          NotificationManager.success("Reembolso completado con exito")
        }

      }else{
        NotificationManager.error("Reembolso no exitoso")
      }
    } catch (e) {
      NotificationManager.error("Error completando reembolso")
    }
    
    loadPagos(50)
    handleClose();
  }

  useEffect(() => {
    console.log("Entering")
    // getFacturacion()
  
  },[] )

 

  return (
    <>
      
      <Table striped bordered hover size="sm" responsive>
        <thead style={{ color: 'white', backgroundColor: '#00295E' }}>
          <tr>
          <th>Cliente</th>
          <th>Número de Factura</th>
          <th>Número de Pago</th>
          <th>Fecha Contabilización</th>
          <th>Importe</th>
          <th style={{ verticalAlign: 'middle', textAlign: 'center' }}>Acción</th>
          </tr>
        </thead>
        <tbody>

          {pagos.map((item, index)=>{
            if(item.Estado__c !== "Reversado"){
              return(
              <tr key={index}>
                <td style={{ verticalAlign: 'middle' }}>{item.CodigoCliente__c}</td>
                <td style={{ verticalAlign: 'middle' }}>{item.Factura__c}</td>
                <td style={{ verticalAlign: 'middle' }}>{item.Correlativo__c}</td>
                <td style={{ verticalAlign: 'middle' }}>{item?.FechaContabilizacion__c ? moment(item.FechaContabilizacion__c).format("DD/MM/YYYY") : null}</td>
                <td style={{ verticalAlign: 'middle', textAlign: 'right' }}>{item.ClaveMoneda__c}. {item.ImportePagado__c}</td>
                <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>
                  {item.FechaContabilizacion__c === moment(new Date()).format("YYYY-MM-DD") && (
                    <Button onClick={() => {
                      handleClickOpen();
                      // SetIdPago(item.ReferenciaBAC__c);
                      // setClaveMoneda(item.ClaveMoneda__c)
                      // setImporte(item.ImportePagado__c)
                      setPagoActual(item)

                      }} style={{ backgroundColor: '#9e9e9e', borderColor: '#9e9e9e', fontWeight: 'bolder'}} size='sm' variant='success'>
                        Devolver
                    </Button>
                  )}
                  
                </td>
              </tr>
              )
            }
          })}


          
        </tbody>
      </Table>

      <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
          {"Reembolso"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            ¿Esta seguro que desea hacer un reembolso del pago seleccionado?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button style={{background:"#f44336",borderColor:"#f44336"}} onClick={handleClose}>Cancelar</Button>
          <Button  onClick={refund} autoFocus>Aceptar </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
