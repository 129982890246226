import React, { useState, useEffect } from 'react'
import { Form, Button,Modal } from 'react-bootstrap'
import axios from 'axios'
import moment from 'moment';
import { NotificationManager } from 'react-notifications';
import "../../assets/styles/estadoCuenta.css"
import { NombreSolicitudCliente } from './nombreSolicitudCliente'
import { CorreoSolicitudCliente } from './correoSolicitudCliente'
import { TelefonoSolicitudCliente } from './telefonoSolicitudCliente'
import { ConsultaSolicitudCliente } from './consultaSolicitudCliente';
import { ApellidoSolicitudCliente } from './apellidoSolicitudCliente';

export default function SolicitudContactoCliente({show,setShow,cliente}) {

  const [clienteSolicitud,setClienteSolicitud] = useState("")
  const [valueNombre,setValueNombre] = useState("")
  const [valueApellido,setValueApellido] = useState("")
  // const [valueCorreo,setValueCorreo] = useState("")
  const [valueTelefono,setValueTelefono] = useState("")
  const [valueConsulta,setValueConsulta] = useState("")
  const [valueNombreFlag,setValueNombreFlag] = useState(true)
  const [valueApellidoFlag,setValueApellidoFlag] = useState(true)
  // const [valueCorreoFlag,setValueCorreoFlag] = useState("")
  const [valueTelefonoFlag,setValueTelefonoFlag] = useState(true)
  const [valueConsultaFlag,setValueConsultaFlag] = useState(true)
  const [paisSeleccionado,setPaisSeleccionado] = useState("")
  
  
  const handleClose = () =>{
    setValueNombre("")
    setValueApellido("")
    setValueTelefono("")
    setValueConsulta("")
    setValueNombreFlag(true)
    setValueTelefonoFlag(true)
    setValueConsultaFlag(true)
    setShow(false);
  }
  
  const getFiveList = (code)=>{
    return "Click2CallPortalPagos"+code.toUpperCase()

  } 

  const sendSolicitud = async() =>{
    console.log("Soliciutd Nonrbre: ",valueNombre,valueApellido,valueTelefono.substring(1,valueTelefono.length),valueConsulta,getFiveList(paisSeleccionado.countryCode))
    if (valueNombreFlag || valueTelefonoFlag || valueConsultaFlag){
      NotificationManager.error("Uno o mas campos estan vacios o inválidos")
    }else{
      try {
    
        const F9domain = "Grupo Solid"
        const F9list = getFiveList(paisSeleccionado.countryCode)
        const number1 = "011"+""+valueTelefono.substring(1,valueTelefono.length)
        const first_name = valueNombre
        const last_name = valueApellido
        const TimeStampClick2Call = moment(new Date()).format('YYYY-MM-DD HH:mm')
        const F9key = "TimeStampClick2Call"
        const Comentarios = valueConsulta

        const url = 'https://api.five9.com/web2campaign/AddToList';
        const params = {
            F9domain,
            F9list,
            number1,
            first_name,
            last_name,
            TimeStampClick2Call,
            Comentarios,
            F9key:"number1",
            F9key:"TimeStampClick2Call"
        };
   
        axios.post(url, null, { params: params,auth:{
            username: 'soporte@experenta.com',
            password: '@@GS_110522'
        }})
        .then(async response => {
            // console.log('Response wewe:', response.data);
            // Handle the response
            await axios.post(process.env.REACT_APP_BACKEND_URL + "solicitud/create/",{
              nombre:valueNombre,
              apellido:valueApellido,
              telefono:valueTelefono,
              consulta:valueConsulta
            })
            NotificationManager.success("Solicitud enviada")
            handleClose()
        })
        .catch(error => {
            console.error('Error:', error);
            // Handle the error
        });
        
  
        
      } catch (error) {
        // console.log("error rquie:",error.response)
        NotificationManager.error("Error al enviar solicitud")
      }
    }

    
  }

  useEffect(() => {
    if (cliente){
      setClienteSolicitud(cliente)
    }
    
  }, [cliente])

 
  return (
    <>
      <Modal 
        show={show} 
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        // dialogClassName="modal-100w"
        size={'lg'}
        className={'modal-solicitud-agente'}
        >
        <Modal.Header closeButton>
          <Modal.Title>Solicitud de Contacto</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <NombreSolicitudCliente value={valueNombre} setValue={setValueNombre} setFlag={setValueNombreFlag}/>
          </Form>
          <Form>
            <ApellidoSolicitudCliente value={valueApellido} setValue={setValueApellido} setFlag={setValueApellidoFlag}/>
          </Form>
          
          {/* <CorreoSolicitudCliente value={valueCorreo} setValue={setValueCorreo} /> */}
          <TelefonoSolicitudCliente clientePais={clienteSolicitud.paisCodigo} value={valueTelefono} setValue={setValueTelefono} setFlag={setValueTelefonoFlag} setPaisSeleccionado={setPaisSeleccionado}/>
          <ConsultaSolicitudCliente value={valueConsulta} setValue={setValueConsulta} setFlag={setValueConsultaFlag}/>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cerrar
          </Button>
          <Button variant="primary" onClick={sendSolicitud}>
            Enviar Solicitud Contacto
          </Button>
        </Modal.Footer>
      </Modal>
      
    </>
  )
}


// <!DOCTYPE HTML PUBLIC "-//W3C//DTD HTML 4.0 Transitional//EN">
// <HTML><HEAD><title>WebToCampaign Response</title>
// <META http-equiv="Content-Type"   content="text/html;">
// </HEAD><BODY>
// <h1>Inputs:</h1>
// <FORM><TABLE>
// </TABLE><BR>
// <h1>Result:</h1>
// <TABLE><TR>
// <TD>Error Code:</TD><TD><INPUT readonly id="F9errCode" name="F9errCode" value="800" size="10"></TD></TR>
// <TR><TD>Error Decription:</TD><TD><INPUT readonly id="F9errDesc" name="F9errDesc" value="Error while executing request " size="30"></TD></TR>
// </TABLE></FORM></BODY></HTML>

