import React, { useState } from 'react'
import { Button, Image, Container, InputGroup, FormControl } from 'react-bootstrap'
import LogoSolidBlanco from '../assets/GUATEMALA GRUPO SOLID/Logo Soli Blanco.png'
import LogoSolid from '../assets/GUATEMALA GRUPO SOLID/Logo Solid.png'
import { useNavigate, useParams } from "react-router-dom";

export default function Landing() {

  const navigate = useNavigate()
  const [idCliente, setIdCliente] = useState('')
  const params = useParams();

  const handleKeyPress = (event) => {
    // console.log("Keny verder")
    if (event.key === "Enter") {
      handleLogin();
    }
  };

  const handleLogin = ()=>{
    navigate({
      pathname: '/portal-pagos/'+idCliente,
    })
    
  }

  // 0110003593
  
  return (
    <div style={{ width: '100%', height: '100%' }}>
      <div style={{
        background: 'linear-gradient(to left, #003E83 , #00295E)',
        width: '100%', height: '60px', display: 'flex', justifyContent: 'space-between',
        alignItems: 'center', padding: '5px'
      }}>
        <Image src={LogoSolidBlanco} style={{ width: 'auto', height: '80px' }} fluid />
        <Button size='sm' onClick={() => {
          navigate({
            pathname: '/login'
          })
        }}
          style={{
            borderRadius: '20px', height: '35px', color: 'white',
            background: '#00295E', fontWeight: 'bold', border: 'none'
          }}>
          Ingresar como agente
        </Button>
      </div>
      <Container>
        <br />
        <br />
        <div style={{
          display: 'flex', justifyContent: 'center',
          borderRadius: '40px',
          boxShadow: '0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 12px 40px 0 rgba(0, 0, 0, 0.19)'
        }}>
          <img style={{
            width: '82%',
            alignSelf: 'center',
            margin: '0 auto', marginTop: '-10%', marginBottom: '-10%'
          }} src={LogoSolid} />
        </div>
        <br />
        <br />
        <InputGroup className="mb-3">
          <FormControl
            placeholder='Ingrese su código de cliente'
            aria-label="Example text with button addon"
            aria-describedby="basic-addon1"
            value={idCliente}
            onChange={e => setIdCliente(e.target.value)}
            onKeyDown={handleKeyPress}
          />
          <Button variant="outline-secondary" id="button-addon1"
            onClick={handleLogin} 
            style={{
              backgroundColor: '#00295E',
              color: 'white'
            }}
          >
            Ingresar
          </Button>
        </InputGroup>
      </Container>
      <div style={{
        background: 'linear-gradient(to left, #003E83 , #00295E)',
        position: 'absolute',
        height: '30px',
        width: '100%',
        bottom: 0
      }} />
    </div>
  )
}
