const sociedades= {
    "C001": "GRUPO SOLIDO",
    "C002": "CONTAINERS CORP (COSTA RICA), S.A.",
    "D001": "DUSOLID, S.A.",
    "G001": "GRUPO SOLID (GUATEMALA), S.A.",
    "G002": "DUSOLID, S.A.",
    "G003": "INTERNACIONAL DE BROCHAS, S.A",
    "G004": "AGROCA, S.A.",
    "G005": "INMOBILIARIA KJR, S.A.",
    "G006": "XINKO (GUATEMALA), S.A.",
    "G007": "REFORESTADORA EL ARBOL, S.A.",
    "G008": "METALMECANICA DE CENTROAMERICA, S.A.",
    "G009": "TEKNO, S.A.",
    "G010": "HOME CENTER, S.A.",
    "G011": "LAPCO (GUATEMALA), S.A.",
    "G012": "RJK, S.A.",
    "G013": "PAINT TEXTILE COMPANY, S.A.",
    "G014": "CENTROAMERICANA DE PINTURAS, S.A.",
    "H001": "LAPCO (HONDURAS), S.A.",
    "H002": "COMPLEJO INDUSTRIAL COMAYAGUA, S.A.",
    "H003": "INMOBILIARIA KJR (HONDURAS), S.A.",
    "H004": "PINCASA (HONDURAS), S.A.",
    "M001": "PINTUDECA, S.A. DE C.V.",
    "N001": "LA PALETA NICARAGUA, S.A.",
    "N002": "PINTURA MODELO, S.A.",
    "N003": "GRUPO SOLID (NICARAGUA), S.A.",
    "N004": "COMERCIAL SGS, S.A.",
    "P001": "GRUPO SOLID WHOLESALE PANAMA, S.A.",
    "P002": "LAPCO PANAMA S.A.",
    "P003": "PINTURAS MODELO PANAMA, S.A.",
    "S001": "GRUPO SOLID (EL SALVADOR),S.A. DE C.V.",
    "S002": "SFC, S.A. DE C.V.",
    "S003": "GRUPO SOLID (GUATEMALA), S.A.",
    "Y001": "PURAS PINTURAS PARAGUAYAS S.A.",
    "Y002": "TIENDAS MONTANA S.A.",
    "Y003": "SOLID PARAGUAY S.A.",
    "Z999": "GSOLID PRES"
  }

  module.exports = sociedades
  