import React, { useState } from 'react'
import { Card, Button, Form, Spinner, Image } from 'react-bootstrap'
import { useNavigate } from "react-router-dom";
import axios from 'axios'
import { NotificationManager } from 'react-notifications';
import LogoSolid from '../assets/GUATEMALA GRUPO SOLID/Logo Solid.png'
import { AiFillMail } from 'react-icons/ai';

export default function Login() {
  localStorage.removeItem("ROLSOLID")
  const navigate = useNavigate()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [loading, setLoading] = useState(false)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [data, setData] = useState({})
  const [user, setUser] = useState({})



  try {
    
  

  } catch (error) {
    console.log("Error rwL ",error.response)
    NotificationManager.error("Error al enviar solicitud")
  }

  

  async function handleLogin(e) {
    e.preventDefault()
    setLoading(true)
    try {
      const response = await axios.post(process.env.REACT_APP_BACKEND_URL + "usuario/auth/",{
        user:email,
        password:password
      })
      console.log("Auth Mngo ",response.data.rol)

      if(response.data.success){
        console.log("ROL")
        localStorage.setItem('rolsolid',response.data.rol);
        navigate({
          pathname: '/portal-agente'
        })
      }else{
        NotificationManager.error("Usuario no encontrado")
      }
      // localStorage.setItem('rolsolid',"SUPERVISOR");
      // navigate({  
      //   pathname: '/portal-agente'
      // })

      
    } catch (error) {
      
      if (error?.response?.data?.message) {
        NotificationManager.error(error?.response?.data?.message)
      } else {
        NotificationManager.error("Ocurrió un error al hacer login")
      }
    } finally {
      setLoading(false)
    }
  }

  return (
    <div style={{ background: 'linear-gradient(#003E83 , #00295E)', width: '100%', height: '100vh' }}>
      <div className='container-login'>
        <Card style={{ width: '400px', borderRadius: '20px',margin:'20px' }}>
          <Card.Body>
            <Image src={LogoSolid} style={{ marginTop: '-20px', marginBottom: '-20px' }} fluid />
            <h4>¡Bienvenido!</h4>
            <Form onSubmit={handleLogin}>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Correo</Form.Label>
                <Form.Control style={{ backgroundColor: '#EDEDED' }} value={email}
                  onChange={e => setEmail(e.target.value)}
                  type="email" placeholder="Ingrese..." />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Contraseña</Form.Label>
                <Form.Control style={{ backgroundColor: '#EDEDED' }} value={password}
                  onChange={e => setPassword(e.target.value)}
                  type="password" placeholder="Ingrese..." />
              </Form.Group>
              <br />
              <Button disabled={loading}
                style={{
                  borderRadius: '20px',
                  width: '100%', background: 'linear-gradient(to right, #003E83 , #00295E)',
                  fontWeight: 'bold'
                }} type="submit">
                INICIAR
              </Button>
              <div style={{ margin: '10px', display: 'flex', justifyContent: 'center' }}>
                {loading ? <Spinner animation="border" size='lg' variant='info' /> : null}
              </div>
            </Form>
          </Card.Body>
          <Card.Footer style={{ textAlign: 'center' }}>
            <a style={{ color: '#00295E', fontWeight: 'bold' }}>Grupo Solid</a>
            <div style={{ color: 'gray', fontSize: '10PX' }}>2022 Grupo SOLID S.A. de C.V. Todos los derechos reservados</div>
          </Card.Footer>
        </Card>
      </div>
    </div>
  )
}
