import axios from 'axios';
import { NotificationManager } from 'react-notifications';

const url = process.env.REACT_APP_API2PDF_URL;
const api2pdfKey = process.env.REACT_APP_API2PDF_KEY;

const GenerarPDF = (htmlParaPdf,callback) => {  
  const dataForPdf = {
    'html': htmlParaPdf,
    'inlinePdf': true,
    'fileName': 'receta.pdf',
    'options': {      
      'orientation': 'portrait',
      'pageSize': 'A4',            
      'marginLeft':'10mm',
      'marginRight':'10mm',
      'marginTop':'5mm'
    }
  };
  return axios.post(url, dataForPdf, {
    headers: {
      'Authorization': api2pdfKey,
    },
  }).then(response => {
    if (response.data.success) {
      if(callback) callback(response.data.pdf)
      window.open(response.data.pdf);
    }
  }).catch(error => {
    NotificationManager.error('Error generando pdf', 'Error');
    console.log('Error generando pdf: ', error);
  })
};

export default GenerarPDF;
