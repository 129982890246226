import React, { useState,useEffect } from 'react'
import { GraficoHome } from './graficoHome'
import { Table, Row, Col } from 'react-bootstrap'
import moment from 'moment'

export default function HomeAgente({solicitudes,pagos}) {
  // const solicitudes = [
  //   { nombre: 'Carlos Ramirez', fecha: new Date() },
  //   { nombre: 'Carlos Ramirez', fecha: new Date() },
  //   { nombre: 'Carlos Ramirez', fecha: new Date() },
  //   { nombre: 'Carlos Ramirez', fecha: new Date() },
  //   { nombre: 'Carlos Ramirez', fecha: new Date() },
  //   { nombre: 'Carlos Ramirez', fecha: new Date() },
  //   { nombre: 'Carlos Ramirez', fecha: new Date() },
  //   { nombre: 'Carlos Ramirez', fecha: new Date() },
  //   { nombre: 'Carlos Ramirez', fecha: new Date() },
  //   { nombre: 'Carlos Ramirez', fecha: new Date() }
  // ]
  const [listaSolicitudes,setListaSolicitudes] = useState([])

  // const pagos = [
  //   { empresa: 'Operadora de Tiendas', monto: 2533.20, moneda: 'HNL', fecha: new Date() },
  //   { empresa: 'Arenal', monto: 2031.20, moneda: 'HNL', fecha: new Date() },
  //   { empresa: 'Operadora de Tiendas', monto: 1533.20, moneda: 'HNL', fecha: new Date() },
  //   { empresa: 'Operadora de Tiendas', monto: 2533.20, moneda: 'HNL', fecha: new Date() },
  //   { empresa: 'Arenal', monto: 2031.20, moneda: 'HNL', fecha: new Date() },
  //   { empresa: 'Operadora de Tiendas', monto: 1533.20, moneda: 'HNL', fecha: new Date() },
  //   { empresa: 'Operadora de Tiendas', monto: 2533.20, moneda: 'HNL', fecha: new Date() },
  //   { empresa: 'Arenal', monto: 2031.20, moneda: 'HNL', fecha: new Date() },
  //   { empresa: 'Operadora de Tiendas', monto: 1533.20, moneda: 'HNL', fecha: new Date() },
  //   { empresa: 'Operadora de Tiendas', monto: 2533.20, moneda: 'HNL', fecha: new Date() },
  // ]

  function parseDate(fecha) {
    return moment(fecha).format('DD/MMM/y')
  }

  useEffect(() => {
    // getSolicitudes()
    if (solicitudes){
      setListaSolicitudes(solicitudes)
    }
    
  }, [solicitudes])

  return (
    <div>
      <Row>
        <Col>
          <h5 style={{color:'rgba(0, 62, 131, 1)',fontWeight:'bold'}}>Solicitudes recientes</h5>
          <Table>
            <tbody>
              {listaSolicitudes.map((x,i) => (
                <tr key={i}>
                  <td style={{fontWeight:'bold'}}>{x.nombre}</td>
                  <td>{parseDate(x.createdAt)}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
        <Col>
          <h5 style={{color:'rgba(0, 62, 131, 1)',fontWeight:'bold'}}>Pagos recientes</h5>
          <Table>
            <tbody>
              {pagos.map((x,i) => (
                <tr key={i}>
                  <td style={{fontWeight:'bold'}}>{x.TextoCompensacionNombreClient__c}</td>
                  <td>{x.ImportePagado__c} {x.ClaveMoneda__c}</td>
                  <td>{parseDate(x.fecha)}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
      {!!pagos.length && (
        <GraficoHome pagos={pagos} />
      )}
      
    </div>
  )
}
