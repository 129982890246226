import React, { useEffect, useState } from 'react'
import exportFromJSON from 'export-from-json'
import { Col, Row, Table, Form, InputGroup, FormControl, Button } from 'react-bootstrap'
import axios from 'axios'
import moment from 'moment'

export default function ReportePago({pagos,loadMoreData}) {

  async function exportXLSX() {    
    const fileName = 'estadoCuenta'
    const exportType = exportFromJSON.types.xls

    exportFromJSON({
      data:pagos, fileName, exportType,
      beforeTableEncode: (rows,cols) => {        
        return rows
      },
    })

  }

  return (
    <>
      <div style={{ width: '100%', display: 'flex', justifyContent: 'end' }}>
        <Button onClick={exportXLSX} style={{ backgroundColor: '#00B050', borderColor: '#00B050', fontWeight: 'bolder', margin: '5px' }} size='sm' variant='success'>
          Exportar a excel
        </Button>
      </div>
      <br />
      <div style={{overflow:"auto",height:"65vh"}}>
        <Table striped bordered hover size="sm" responsive>
          <thead style={{ color: 'white', backgroundColor: '#00295E' }}>
            <tr>
              <th>No.</th>
              <th>Cliente</th>
              <th>Número de Factura</th>
              <th>Número de Pago</th>
              <th>Fecha Contabilización</th>
              <th>Importe</th>
            </tr>
          </thead>
          <tbody>
            {pagos.map((item, index) => (
              <tr key={index}>
                <td style={{ verticalAlign: 'middle' }}>{index + 1}</td>
                <td style={{ verticalAlign: 'middle' }}>{item.CodigoCliente__c}</td>
                <td style={{ verticalAlign: 'middle' }}>{item.Factura__c}</td>
                <td style={{ verticalAlign: 'middle' }}>{item.Correlativo__c}</td>
                <td style={{ verticalAlign: 'middle' }}>{item?.FechaContabilizacion__c ? moment(item.FechaContabilizacion__c).format("DD/MM/YYYY") : null}</td>
                <td style={{ verticalAlign: 'middle', textAlign: 'right' }}>{item.ClaveMoneda__c}. {item.ImportePagado__c}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      <div style={{height:"5vh"}}>
        <button style={{float:"right"}} onClick={()=>loadMoreData()}>Ver Más</button>
      </div>
      
      
    </>
  )
}
