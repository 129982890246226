import React,{useEffect, useState} from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';


ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const options = {
  responsive: true,  
  plugins: {
    legend: {
      display:false,
    },
    title: {
      display: true,
      text: 'Pagos por día',
    },
  },
};

const labels = ['08/19', '09/21', '09/20', '09/26', '09/27', '09/28', '09/30'];

const dataInitial = {
  labels,
  datasets: [
    {
      label: 'Pagos',
      data: [2000,3000,1500,900,2500,2600,1300],
      backgroundColor: 'rgba(0, 62, 131, 1)',
    }
  ],
};

export function GraficoHome({pagos}) {
  const [data,setData] = useState(null)

  const loadData = ()=>{
    const labels = [...new Set(pagos.map(x=>(x.FechaContabilizacion__c)))]
    const valores = []
    labels.forEach(x=>{
      let acum = 0
      pagos.forEach(y=>{
        if (y.FechaContabilizacion__c === x){
          acum = Math.floor(y.ImportePagado__c) + acum
        }
      })
      valores.push(acum)
    })  
    const dataTemp = {...dataInitial}
    dataTemp.labels = labels
    dataTemp.datasets[0].data = valores
    setData(dataTemp)

  }

  useEffect(() => {
    if (pagos) loadData()
  }, [pagos])
  

  return (<>
    {data && (<Bar  options={options} data={data} />)}
    
    </>)
  }
