import React, { useState, useEffect } from 'react'
import { Row, Col, Form, Image, Button } from 'react-bootstrap'
import CreditCards from '../../assets/creditcards.png'
import VISA from '../../assets/Visa_Brandmark_Blue_RGB_2021.png'
import MASTERCARD from '../../assets/LOGO MASTERCARD.png'
import InputCard from './componentsPago/inputCard'
import InputCVV from './componentsPago/inputCVV'
import InputExpirationDate from './componentsPago/inputExpirationDate'
import { FiExternalLink } from 'react-icons/fi'
import BlurLoading from '../commons/loading'
import { NotificationManager } from 'react-notifications'
import axios from 'axios'
import Pusher from 'pusher-js'
import { memo } from "react";
import { PaidSharp } from '@mui/icons-material'

function Pago({setComponent, paymentData, setPaymentData,pusher }) {
 
  const [newData, setNewData] = useState({})
  const [showDbErrorMessage, setShowDbErrorMessage] = useState(false)
  const [savingChangesDb, setSavingChangesDb] = useState(false)
  const [showDbSuccessMessage, setShowDbSuccessMessage] = useState(false)
  const [aceptarTerminos, setAceptarTerminos] = useState(false)
  const [error, setError] = useState(false)
  const [pushedAlready, setPushedAlready] = useState(false)
  

  function pusherConection() {
    try{
      
  
      const channel = pusher.subscribe('powertranz-log')
  
      channel.bind('payReady', async (pushed) => {
        console.log("pushed", pushed)
        if (pushedAlready) return
        setPushedAlready(true)
        endLoadingAnimationSuccess(() => {
          NotificationManager.success('Transacción autorizada con éxito.')
          const newData = {...paymentData,pushed}
        
          setPaymentData(newData)
          setComponent("Comprobante")
          pusher.disconnect()
        })
      })
    }catch(e){
      endLoadingAnimationSuccess(() => {
        NotificationManager.error('Error al autorizar la transacción.')
      })
    }
  }

  const endLoadingAnimationSuccess = (onSuccess = () => { }) => {
    setTimeout(() => {
      setShowDbSuccessMessage(true)
      setTimeout(() => {
        setSavingChangesDb(false)
        setShowDbSuccessMessage(false)
        onSuccess()
      }, 1500)
    }, 2000)
  }

  const endLoadingAnimationFailure = (onFailure = () => { }) => {
    setTimeout(() => {
      setShowDbErrorMessage(true)
      setTimeout(() => {
        setSavingChangesDb(false)
        setShowDbErrorMessage(false)
        onFailure()
      }, 1500)
    }, 2000)
  }

  async function pay() {
    if (!newData.cvv || !newData.fechaExpiracion || !newData.numeroTarjeta || !newData.titularTarjeta) {
      NotificationManager.warning("Algunos campos estan vacios, favor ingresa la información de pago")
      return
    }
    if (error) {
      NotificationManager.warning("Error en los campos de pago")
      return
    }
    if (!aceptarTerminos) {
      NotificationManager.info("Debes aceptar los términos y condiciones")
      return
    }
    pusherConection()
    try {
      setSavingChangesDb(true)
      // console.log("Data for autorize i need: ",paymentData,newData)
      const response = await axios.post(process.env.REACT_APP_BACKEND_URL + "pay/authPayment",
        {
          ...newData,
          moneda: paymentData.monedaPago,
          pagoTotal: paymentData.montoPagar,
          pais:paymentData.pais,
          sociedad:paymentData.facturas[0].Sociedad
        }
      )
      const spiToken = response.data.SpiToken
      console.log("pay/authPayment", response.data)
      if (!spiToken) {
        endLoadingAnimationFailure(() => {
          NotificationManager.error('Ocurrió un error al autorizar el pago. BAC: ' + response.data.ResponseMessage)
        })
      } else {
        var wnd = window.open("about:blank", "", "resizable=1,width=500,height=600")
        wnd.document.write(response.data.RedirectData)
      }
    } catch (error) {
      endLoadingAnimationFailure(() => {
        NotificationManager.error('Ocurrió un error al cerrar la orden de pago.')
      })
      console.log("ERROR 01", error)
    }

    

    
  }

  return (
    <>
      <Row>

        <Col xs={12} md={2} />
        <Col xs={12} md={8}>
          <Form >
            <br />
            <h5 style={{ textAlign: 'center', fontWeight: 'bold' }}>Monto a pagar</h5>
            <h3 style={{ textAlign: 'center', fontWeight: 'bold' }}>{paymentData.monedaPago} {Number(paymentData.montoPagar).toFixed(2)}</h3>
            <div style={{ display: 'flex', justifyContent: 'end', width: '100%', height: '50px' }}>
              <Image src={VISA} fluid />
              <Image src={MASTERCARD} fluid />
              
            </div>
            <BlurLoading savingChangesDb={savingChangesDb}
              showDbSuccessMessage={showDbSuccessMessage} showDbErrorMessage={showDbErrorMessage} />
            <Row className="mb-3">
              <InputCard newData={newData} setNewData={setNewData} setErrorParent={setError} />
            </Row>
            <Row className="mb-3">
              <InputExpirationDate newData={newData} setNewData={setNewData} setErrorParent={setError} />
              <InputCVV newData={newData} setNewData={setNewData} setErrorParent={setError} />
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="test1">
                <Form.Label>Titular de la tarjeta</Form.Label>
                <Form.Control required type="input" placeholder="Ingrese..."
                  style={{ borderLeft: '10px solid #00295E' }}
                  value={newData.titularTarjeta || ''}
                  onChange={e => setNewData({ ...newData, titularTarjeta: e.target.value })} />
              </Form.Group>
            </Row>
            <Form.Group className="mb-3" controlId="formBasicCheckbox">
              <Form.Check type="checkbox"
                value={aceptarTerminos}
                onChange={e => setAceptarTerminos(e.target.checked)}
                label={<label>Aceptar términos y condiciones
                  <a href={"https://pagos.gruposolidca.com/terminos-y-condiciones"} target="_blank">
                    <FiExternalLink style={{ cursor: 'pointer', color: '#00295E' }} /></a></label>} />
            </Form.Group>
            <div style={{ width: '100%', display: 'inline-block', textAlign: 'end' }}>
              <Button variant='secondary' onClick={() => setComponent("EstadoCuenta")} style={{ fontWeight: 'bolder', margin: '5px' }} >Regresar</Button>
              <Button style={{ backgroundColor: '#00B050', borderColor: '#00B050', fontWeight: 'bolder', margin: '5px' }}
                onClick={() => { pay()/*setComponent("Comprobante")*/ }}
              >Pagar</Button>
            </div>
            <br />
            <br />
          </Form>
        </Col>
      </Row>
    </>
  )
}


export default memo(Pago)