import React, { useState, useEffect } from 'react'
import { Col, Row, Form, InputGroup, FormControl } from 'react-bootstrap'
import "../../assets/styles/estadoCuenta.css"

function ApellidoSolicitudCliente({value,setValue,setFlag}) {

  const [invalid,setInvalid] = useState(false)

  const validacion = (event)=>{
    const name = event.target.value
    setValue(name)

    var regexName = new RegExp("^[a-zA-Z\sáéíóúâêôãõçÁÉÍÓÚÂÊÔÃÕÇüñÜÑ ]+$")
  
    if (regexName.test(name) ){
      setInvalid(false)
      setFlag(false)
    }else{
      setInvalid(true)
      setFlag(true)
    }
  }


  

 
  return (
    <>
        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Apellido</Form.Label>
            <Form.Control
            placeholder="Escriba su primer apellido"
            aria-label="Username"
            isInvalid={invalid}
            autoFocus
            value={value}
            onChange={validacion}
            />
            <Form.Control.Feedback type="invalid">
              Nombre inválido
            </Form.Control.Feedback>
        </Form.Group>
    </>
  )
}

export {ApellidoSolicitudCliente};
