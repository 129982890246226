import React, { useRef, useEffect, useState } from 'react'
import { Col, Form } from 'react-bootstrap'

export default function InputCVV({ newData, setNewData, setErrorParent = () => { } }) {
  const [cvv, setCvv] = useState();
    const [typed, setTyped] = useState(false);
    const [error, setError] = useState(false);
    const [errorMsg, setErrorMsg] = useState(' ')
    const inputCVV = useRef();

    const handleChange = () => {
        const cardValue = inputCVV.current.value
            .replace(/\D/g, '')
            .match(/(\d{0,4})/);
        var val = cardValue[1];        
        inputCVV.current.value = val;
        const numbers = inputCVV.current.value.replace(/(\D)/g, '')
        setCvv(numbers);  
        setNewData({ ...newData, cvv: numbers })
    }

    useEffect(() => {
        handleChange();
        if (typed){
            if ( cvv === '') {
                setError(true)
                setErrorParent(true);
                setErrorMsg('CVV vacío')
            } else {
                setError(false);
                setErrorParent(false);
                setErrorMsg(' ')
            }
        }
    }, [cvv])

    const onBlur = () => {
        if (typed){
            if ( cvv === '') {
                setError(true)
                setErrorParent(true);
                setErrorMsg('CVV vacío')
            } else if ( cvv.length < 3) {
                setError(true)
                setErrorParent(true);
                setErrorMsg('CVV incompleto')
            } else {
                setError(false);
                setErrorParent(false);
                setErrorMsg(' ')
            }
        }
    }

  return (
    <Form.Group as={Col} controlId="test1">
      <Form.Label>CVV</Form.Label>
      <Form.Control required type="input" placeholder="Ingrese..."
        style={{ borderLeft: '10px solid #00295E' }}
        ref={inputCVV}
        onChange={() => { handleChange(); setTyped(true) }}
        onBlur={onBlur} />
      <Form.Text style={{ color: 'red' }}>
        {errorMsg ?? ''}
      </Form.Text>
    </Form.Group>
  )
}
