import React, { useState, useEffect } from 'react'
import { Col, Row, Form, InputGroup, FormControl } from 'react-bootstrap'
import "../../assets/styles/estadoCuenta.css"

function ConsultaSolicitudCliente({value,setValue,setFlag}) {
    const [invalid,setInvalid] = useState(false)

    const validacion = (event)=>{
    const name = event.target.value
    setValue(name)
    
    if (name !== "" ){
        setInvalid(false)
        setFlag(false)
    }else{
        setInvalid(true)
        setFlag(true)
    }

  }


  

 
  return (
    <>
        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Consulta/Observación</Form.Label>
            <Form.Control
            placeholder=""
            aria-label="consulta"
            value={value}
            onChange={validacion}
            isInvalid={invalid}
            as="textarea"
            />
            <Form.Control.Feedback type="invalid">
              Consulta vacia
            </Form.Control.Feedback>
        </Form.Group>
    </>
  )
}

export {ConsultaSolicitudCliente};
