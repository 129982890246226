import React, { useState,useEffect } from 'react'
import { Row, Col, Table, Button,Modal,Image } from 'react-bootstrap'
import axios from 'axios'
import moment from 'moment'
import GenerarPDF from '../../utils/generarPDF'
import ReactDOMServer from 'react-dom/server'
import { NotificationManager } from 'react-notifications'
import BlurLoading from '../commons/loading'
import EXPIRADA from '../../assets/expirado.png'
import ProgressBar from 'react-bootstrap/ProgressBar';


export default function Comprobante({ setComponent, paymentData, cliente, setDataPDFfin }) {

  const [showDbErrorMessage, setShowDbErrorMessage] = useState(false)
  const [savingChangesDb, setSavingChangesDb] = useState(false)
  const [showDbSuccessMessage, setShowDbSuccessMessage] = useState(false)
  const [showTimeoutModal,setShowTimeoutModal] = useState(false)
  const durationInMinutes = 5;
  const durationInMilliseconds = durationInMinutes * 60 * 1000;
  
  const [progress, setProgress] = useState(100);
  const [timeRemaining, setTimeRemaining] = useState(durationInMilliseconds);

  const minutes = Math.floor(timeRemaining / 1000 / 60);
  const seconds = Math.floor((timeRemaining / 1000) % 60);
  const timeLabel = `${minutes}m ${seconds}s`;

  const getAddress = (pais,sociedad)=>{
    if(sociedad === "N002"){
      return ["RUC: J0310000009074","Oficina Central","Carretera a Masaya Km 13.5","Teléfono: 2279-9074"]
    }else if(sociedad === "G001"){
      return ["12 calle 1-25 zona 10 Edificio Géminis 10 Torre Sur Nivel 18","Oficina 1802, Guatemala, Guatemala","NIT: 549234-3","PBX: 2320-6264","servicioalclientecorona@gruposolid.com"]
    }else if(sociedad === "H001"){
      return ["OFICINA PRINCIPAL. KM. 10, BÚFALO VILLANUEVA,","CORTES PBX: (504) 2270-6001 HONDURAS, C.A.","RTN: 05019001062297","www.gruposolidca.com, www.pinturascorona.com","email: lapcohonduras@gruposolid.com"]
    }else if(sociedad === "N001"){
      return ["RUC: J0310000001596","Oficina Central","Km 13.7 Carretera a Masaya","Teléfono: 2276-8090"]
    }else if(sociedad === "P001"){
      return ["Ave. José María Torrijos Las Mañanitas,","Tocumen, Plaza Las Mañanitas frente a","la barriada Los Nogales Local 4","pinturascoronapa@gruposolid.com","RUC: 61640-33-349863 DV 33","TEL: 229-8373"]
    }else if(sociedad === "S001"){
      return ["Km. 11 1/2, Carretera Troncal del Norte, frente","a Paso a Desnivel, Apopa, San Salvador.","PBX: 2327-5600","NRC: 224-0","NIT: 0614-121181-002-3"]
    }else if(sociedad === "C001"){
      return ["Ident. Jurídica: 3-101-131155","Teléfono: +(506) 4404-1160","Dirección: Costado Sur de EPA en Colima","Condominio Condal Tibás, Bodegas Local 63,","Oficinas Local 27.."]
    }else{
      return [""]
    }
  }


  const comprobante = {
    sociedadTitle: paymentData.sociedadTitle,
    direccion: getAddress(cliente.paisCodigo,paymentData.facturas[0].Sociedad),
    // telefono: '(502) 2320-6262',
    correlativo: paymentData.correlativo,
    fechaImpreso: moment(new Date()).format('DD/MM/YYYY hh:mm a'),
    usuario: 'TELEPAGO_POSBAC',
    fechaPago: moment(new Date()).format('DD/MM/YYYY'),
    numeroAutorizacion: "-------"/*paymentData.backendData.AuthorizationCode,*/,
    identificador: paymentData.pushed.TransactionIdentifier,
    codigoCliente: cliente.KUNNR__c,
    nombre: cliente.Name,
    documentosAbonados: paymentData.facturas,
    mediosPago: [{
      medio: 'PosVirtualBAC',
      monto: paymentData.monedaPago + ' ' + (Number(paymentData.montoPagar).toFixed(2))
    }]
  }

  const handleClose = ()=>{
    setShowTimeoutModal(false)
    handleCancelar()
  }


  const getFACID = (pais)=>{
    if(pais === "GT"){
      return "88804581"
    }else if(pais === "HN"){
      return "88804399"
    }else if(pais === "NI"){
      return "88804363"
    }else if(pais === "PA"){
      return "88804481"
    }else if(pais === "SV"){
      return "88804549"
    }else if(pais === "CR"){
      return "88804797"
    }else{
      return ""
    }
  }


  async function handleCancelar() {
    console.log('paymentData',paymentData)
    setSavingChangesDb(true)


    endLoadingAnimationSuccess(() => {
      NotificationManager.success('Transacción cancelada con éxito')
    })
    setComponent('EstadoCuenta')
    
  }

 
  const endLoadingAnimationSuccess = (onSuccess = () => { }) => {
    setTimeout(() => {
      setShowDbSuccessMessage(true)
      setTimeout(() => {
        setSavingChangesDb(false)
        setShowDbSuccessMessage(false)
        onSuccess()
      }, 1500)
    }, 2000)
  }

  const endLoadingAnimationFailure = (onFailure = () => { }) => {
    setTimeout(() => {
      setShowDbErrorMessage(true)
      setTimeout(() => {
        setSavingChangesDb(false)
        setShowDbErrorMessage(false)
        onFailure()
      }, 1500)
    }, 2000)
  }

  async function handleFinalizar() {
    let response3 = false
    let data2 = false
    setSavingChangesDb(true)
    const response2 = await axios.post(process.env.REACT_APP_BACKEND_URL + "pay/payment",
      {
        spiToken: paymentData.pushed.SpiToken
      }
    )
    const data = response2.data
    console.log("pay/payment/", data)
    if(data.Approved){
      response3 = await axios.post(process.env.REACT_APP_BACKEND_URL + "pay/capture",{
        ...data,
        pais:paymentData.pais,
        sociedad:paymentData.facturas[0].Sociedad
       })
      data2 = response3.data
      console.log("pay/payment/capture", response3.data)
    }else{
      endLoadingAnimationFailure(() => {
        NotificationManager.error('Transacción denegada ')
      })
    }
    
    if (data2.Approved === true) {
      
      endLoadingAnimationSuccess(async() => {
        paymentData.facturas.forEach(async(element,index) => {
          var dataTransaccion = {
            TransactionIdentifier__c: data2.OriginalTrxnIdentifier,
            TransactionType__c: 3,
            TextoCabecera__c: element.Cabecera,
            TextoCompensacion__c: cliente.Name,
            AsignacionPosicionBanco__c: parseInt(response2.data.AuthorizationCode),
            TextoPosicionBanco__c: 'Pasarela Grupo Solid - '+cliente.paisNombre,
            NumeroDocumentoFactura__c: parseInt(element.No_Factura),
            EjercicioFactura__c: element.Ejercicio,
            NumeroCuentaBanco__c: getFACID(cliente.paisCodigo),//*NumeroCuentaBanco__c
            NumeroCuentaCliente__c: parseInt(cliente.KUNNR__c),
            FechaContabilizacion__c: moment(new Date()).format("YYYY-MM-DD"),
            FechaDocumento__c: moment(new Date()).format("YYYY-MM-DD"),
            ClaveMoneda__c: paymentData.monedaPago,
            TotalAmount__c: Number(element.abono),
            PartidaAbierta__c: element.IdPartida,
            Payment_Identifier__c:data2.OriginalTrxnIdentifier+"-"+index,
            OrderIndentifier__c: data2.ExternalIdentifier,
            Correlativo__c: comprobante.correlativo,
            Account__c:cliente.Id
  
          }
          console.log("Send Transaccion: ",dataTransaccion)
          try{
            const bearer = localStorage.getItem('bearer')
            //data.facturaId = paymentData.facturas[0].Serie;
            const responseSale = await axios.post(process.env.REACT_APP_BACKEND_URL + "crm/PagoBac/", dataTransaccion,{
              headers: {
                Authorization: "Bearer " + "user.token",
                CRMtoken: bearer
              }
            })
            console.log("Fim pagos saleforece: ",responseSale)
          } catch (e) {
            NotificationManager.info("Error completado pago")
          }
          
        });

        NotificationManager.success('Transacción exitosa. BAC: ' + response2.data.ResponseMessage)
        const newPayData = { ...paymentData, ...comprobante, backendData: response2.data }
        setDataPDFfin({ comprobante, paymentData, backendData: response2.data })
        console.log('newPayData', newPayData)
        
        setComponent("Fin")
        
      })
      //console.log("Comprobante paymentdata: ",paymentData)

      
      
    } else if (data.Approved === false) {
      endLoadingAnimationFailure(() => {
        NotificationManager.error('Transacción denegada. BAC: ' + response2.data.ResponseMessage)
      })
    }



  }

  const getSociedad = (grupoCliente)=>{
    let sociedadTemp
    if (grupoCliente === "G001"){
      sociedadTemp = "G001 - GRUPO SOLID, S.A."
    }else if(grupoCliente === "D001"){
      sociedadTemp = "D001 - GRUPO SOLID DOM. SAS"
    }else if(grupoCliente === "G002"){
      sociedadTemp = "G002 - CONTAINERS CORP COST"
    }else if(grupoCliente === "G600"){
      sociedadTemp = "G600 - DUSOLID, S.A."
    }else if(grupoCliente === "S001"){
      sociedadTemp = "S001 - GRUPO SOLID EL SALV"
    }else if(grupoCliente === "S002"){
      sociedadTemp = "S002 - S.F.C., S.A. DE C.V."
    }else if(grupoCliente === "C001"){
      sociedadTemp = "C001 - GRUPO SOLIDO S.A."
    }else if(grupoCliente === "C002"){
      sociedadTemp = "C002 - CONTAINERS CORP COST"
    }else{
      sociedadTemp = ""
    }

    return sociedadTemp
  }

  const getNumFactura = (cabecera,referencia)=>{
    //SV,HN,NI - Referencia
    //GT, CR, RD, PY, PA  - Texto Cabecera
    if(cliente.paisCodigo === "SV" || cliente.paisCodigo === "HN" || cliente.paisCodigo === "NI"){
      return referencia
    }else if(cliente.paisCodigo === "GT" || cliente.paisCodigo === "CR" || cliente.paisCodigo === "RD" || cliente.paisCodigo === "PY" || cliente.paisCodigo === "PA" ){
      return cabecera
    }else{
      return ""
    }
    
  }

  const getNumSerie = (cabecera,referencia)=>{
    //SV,HN,NI - Referencia
    //GT, CR, RD, PY, PA  - Texto Cabecera
    if(cliente.paisCodigo === "SV" || cliente.paisCodigo === "HN" || cliente.paisCodigo === "NI"){
      return cabecera
    }else if(cliente.paisCodigo === "GT" || cliente.paisCodigo === "CR" || cliente.paisCodigo === "RD" || cliente.paisCodigo === "PY" || cliente.paisCodigo === "PA" ){
      return referencia
    }else{
      return ""
    }
    
  }

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     setShowTimeoutModal(true);
  //   }, 2000); // 10 seconds

  //   //5 minutes
  //   // 300,000
  
  //   // Cleanup function to clear the timeout when the component unmounts
  //   return () => clearTimeout(timer);
  // }, []);

  useEffect(() => {
    const startTime = Date.now();

    const timer = setInterval(() => {
      const elapsedTime = Date.now() - startTime;
      const remainingTime = durationInMilliseconds - elapsedTime;
      const remainingProgress = 100 - (elapsedTime / durationInMilliseconds) * 100;

      if (remainingProgress <= 0) {
        setProgress(0);
        setTimeRemaining(0);
        clearInterval(timer);
        setShowTimeoutModal(true)
      } else {
        setProgress(remainingProgress);
        setTimeRemaining(remainingTime);
      }
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);


  

  return (
    <>
      <BlurLoading savingChangesDb={savingChangesDb}
        showDbSuccessMessage={showDbSuccessMessage} showDbErrorMessage={showDbErrorMessage} />
      <Modal show={showTimeoutModal} size="lg" onHide={handleClose} centered={true} >
        {/* <Modal.Header closeButton>
          <Modal.Title>Tu sesión expiro</Modal.Title>
        </Modal.Header> */}
        <Modal.Body style={{textAlign:"center"}}>
        <h1 style={{textAlign:"center",color:"#0c5ba1"}}>Tu sesión expiró</h1>
        <br/>
        <Image style={{textAlign:"center",height:"25vh"}} src={EXPIRADA} fluid />
        <br/>
        <p style={{textAlign:"center",fontSize:"20px"}}>Lo sentimos pero tu sesión ha terminado</p>
        <p style={{textAlign:"center",fontSize:"20px"}}>Puede comenzar nuevamente dando click al siguiente botón</p>
        <br/>
        <br/>
        <Button variant="secondary" onClick={handleClose} style={{background:"#06599c",color:"white",width:"30%"}}>
          Ir al inicio
        </Button>
        </Modal.Body>
      </Modal>
      <Row >
        <Col xs={12} md={2} />
        <Col xs={12} md={8} >
          <br />
          <div style={{textAlign:"right",color:"#FF5722"}}>Tiempo restante: {timeLabel}</div>
          <ProgressBar now={progress} 
          />
          <h5 style={{ textAlign: 'center', fontWeight: 'bold' }}>Resumen de Pago</h5>
          <div style={{ width: '100%', display: 'inline-block', textAlign: 'end' }}>
            <Button onClick={handleCancelar} variant='danger' style={{ margin: '5px', fontWeight: 'bolder' }}>Cancelar transacción</Button>
           
            <Button onClick={handleFinalizar} style={{ margin: '5px', backgroundColor: '#00B050', borderColor: '#00B050', fontWeight: 'bolder' }}>Confirmar Transacción</Button>
          </div>
          <div style={{ border: '3px solid black', padding: '30px' }}>
            <div style={{ textAlign: 'center' }}>
              <h4 style={{ fontWeight: 'bold' }}>{paymentData.sociedadTitle}</h4>
              <br />
              {comprobante.direccion.map((x) => {
                return <>
                    {x} <br/>
                  </>
              })}
            </div>
            <br />
            {/* <Table style={{ width: '90%', border: '0px solid white' }} responsive size="xs">
              <tbody >
                <tr>
                  <td style={{ fontWeight: 'bold' }}>Impreso el:</td>
                  <td style={{ textAlign: 'right', }}>{comprobante.fechaImpreso}</td>
                </tr>
                <tr>
                  <td style={{ fontWeight: 'bold' }}>Usuario:</td>
                  <td style={{ textAlign: 'right', }}>{comprobante.usuario}</td>
                </tr>
                <tr>
                  <td style={{ fontWeight: 'bold' }}>Correlativo:</td>
                  <td style={{ textAlign: 'right', }}>{comprobante.correlativo}</td>
                </tr>
              </tbody>
            </Table> */}
            <Row>
              <Col xs={12} sm={12} md={{span: 0}} lg={{span: 6}} style={{textAlign:"center",fontWeight:"bold"}}>
                Impreso el:
              </Col>
              <Col xs={12} sm={12} md={6} lg={6} style={{textAlign:"center"}}>
                {comprobante.fechaImpreso}
              </Col>
              <Col xs={12} sm={12} md={6} lg={6} style={{textAlign:"center",fontWeight:"bold"}}>
                Usuario:
              </Col>
              <Col xs={12} sm={12} md={6} lg={6} style={{textAlign:"center",}}>
                {comprobante.usuario}
              </Col>
              <Col xs={12} sm={12} md={6} lg={6} style={{textAlign:"center",fontWeight:"bold"}}>
                Correlativo:
              </Col>
              <Col xs={12} sm={12} md={6} lg={6} style={{textAlign:"center"}}>
                {comprobante.correlativo}
              </Col>
            </Row>
            <br />
            <h4 style={{ fontWeight: 'bold', textAlign: 'center' }}>AUTORIZACIÓN DE PAGO</h4>
            <br />
            {/* <Table style={{ width: '100%', border: '0px solid white' }} responsive size="sm">
              <tbody >
                <tr>
                  <td style={{ fontWeight: 'bold' }}>Fecha:</td>
                  <td style={{ textAlign: 'right', }}>{comprobante.fechaPago}</td>
                </tr>
                <tr>
                  <td style={{ fontWeight: 'bold' }}>Identificador:</td>
                  <td style={{ textAlign: 'right', }}>{comprobante.identificador}</td>
                </tr>
                <tr>
                  <td style={{ fontWeight: 'bold' }}>Código cliente:</td>
                  <td style={{ textAlign: 'right', }}>{cliente.KUNNR__c}</td>
                </tr>
                <tr>
                  <td style={{ fontWeight: 'bold' }}>Nombre:</td>
                  <td style={{ textAlign: 'right', }}>{comprobante.nombre}</td>
                </tr>
              </tbody>
            </Table> */}
            <Row>
              <Col xs={12} sm={12} md={{span: 0}} lg={{span: 6}} style={{textAlign:"center",fontWeight:"bold"}}>
                Fecha:
              </Col>
              <Col xs={12} sm={12} md={6} lg={6} style={{textAlign:"center"}}>
                {comprobante.fechaPago}
              </Col>
              <Col xs={12} sm={12} md={6} lg={6} style={{textAlign:"center",fontWeight:"bold"}}>
                Identificador:
              </Col>
              <Col xs={12} sm={12} md={6} lg={6} style={{textAlign:"center",}}>
                {comprobante.identificador}
              </Col>
              <Col xs={12} sm={12} md={6} lg={6} style={{textAlign:"center",fontWeight:"bold"}}>
                Código cliente:
              </Col>
              <Col xs={12} sm={12} md={6} lg={6} style={{textAlign:"center"}}>
                {cliente.KUNNR__c}
              </Col>
              <Col xs={12} sm={12} md={6} lg={6} style={{textAlign:"center",fontWeight:"bold"}}>
                Nombre:
              </Col>
              <Col xs={12} sm={12} md={6} lg={6} style={{textAlign:"center"}}>
                {comprobante.nombre}
              </Col>
            </Row>

            <br />
            <h4 style={{ fontWeight: 'bold', textAlign: 'center' }}>DOCUMENTOS ABONADOS</h4>
            <Table style={{ width: '100%', border: '0px solid white' }} responsive size="sm">
              <tbody>
                {comprobante.documentosAbonados.map((item, index) => (
                  <tr key={index}>
                    <td style={{ textAlign: 'center' }}>{getNumFactura(item.Cabecera,item.Referencia)} </td>
                    <td style={{ textAlign: 'center' }}>{getNumSerie(item.Cabecera,item.Referencia)}</td>
                    <td style={{ textAlign: 'center' }}>{paymentData.monedaPago + ' ' + Number(item.abono).toFixed(2)}</td>
                  </tr>
                ))}
                <tr>
                  <td />
                  <td style={{ textAlign: 'center', fontWeight: 'bold' }}>Total:</td>
                  <td style={{ textAlign: 'center', }}>{paymentData.monedaPago} {Number(paymentData.montoPagar).toFixed(2)}</td>
                </tr>
              </tbody>
            </Table>
            <br />
            <h4 style={{ fontWeight: 'bold', textAlign: 'center' }}>MEDIOS DE PAGO</h4>
            <Table style={{ width: '100%', border: '0px solid white' }} responsive size="sm">
              <tbody >
                {comprobante.mediosPago.map((item, index) => (
                  <tr key={index}>
                    <td>{item.medio}</td>
                    <td style={{ textAlign: 'right' }}>{item.monto}</td>
                  </tr>
                ))}
                <tr>
                  <td style={{ textAlign: 'right', fontWeight: 'bold' }}>Total:</td>
                  <td style={{ textAlign: 'right', }}>{paymentData.monedaPago} {Number(paymentData.montoPagar).toFixed(2)}</td>
                </tr>
              </tbody>
            </Table>
            <br />
            <br />
            <a>*Este no es un recibo oficial de caja.</a>
            <div>www.gruposolidca.com</div>
          </div>
          <br />
        </Col>
      </Row>

      <br />
      <br />
    </>
  )
}
