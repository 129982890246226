import React, { useState, useEffect } from 'react'
import { Col, Row, Table, Form, InputGroup, FormControl, Button,Modal } from 'react-bootstrap'
import { useNavigate } from "react-router-dom";
import axios from 'axios'
import { NotificationManager } from 'react-notifications';
import "../../assets/styles/estadoCuenta.css"
import moment from 'moment'
import SolicitudContactoCliente from './solicitudContactoCliente.js'
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';


export default function EstadoCuenta({ setComponent, cliente,sociedad,sociedadTitle ,setPaymentData }) {


  const [montoCalcular, setMontoCalcular] = useState(0)
  const [pagos, setPagos] = useState([])
  const [factorDolar, setFactorDolar] = useState(0)
  const [monedaPago, setMonedaPago] = useState('')
  const [show, setShow] = useState(false);
  const [showDollar,setShowDollar] = useState("visible")
  const [correlativo,setCorrelativo] = useState("AAAA0000A")
  const [loading,setLoading]= useState(false)

 
  



  const navigate = useNavigate()

 

  useEffect(() => {
    async function getFacturacion() {
      setLoading(true)
      const bearer = localStorage.getItem('bearer')
      const response = await axios.get(process.env.REACT_APP_BACKEND_URL + "crm/getFacturacion/" + cliente.Id, {
        headers: {
          Authorization: "Bearer " + "user.token",
          CRMtoken: bearer
        }
      })
      try {
        const responseMoney = await axios.get(process.env.REACT_APP_BACKEND_URL + "pay/getCurrencyFactor/" + cliente.moneda + "/USD", {
          headers: {
            Authorization: "Bearer " + "user.token",
            CRMtoken: bearer
          }
        })
        setFactorDolar(responseMoney.data.currencyFactor)
      } catch (e) {
        NotificationManager.info("Error obteniendo conversion para el dolar")
      }

      if (response.data.success) {
        const newFacturas = response.data.facturas.map(x => ({ ...x, abono: x.SaldoDPPlocal}))
        setPagos(newFacturas.filter(item=>(item.Sociedad === sociedad)))
      } else {

      }
      setLoading(false)
    }
    if (cliente?.Id){
      getFacturacion()
      loadPagos()
      setShowDollar(cliente.moneda === "USD" ? "invisible":"visible")
    }
  }, [cliente,sociedad])

  useEffect(() => {
  
    if (pagos?.length > 0) {
      console.log("pagosLA: ",pagos)
      const aPagar = pagos.filter(x => x.aPagar)
      if (aPagar.length > 0) {
        const data = aPagar.map(item => parseFloat(item.abono) ?? 0).reduce((prev, next) => prev+ next);
        setMontoCalcular(data)
      } else {
        setMontoCalcular(0)
      }
    }

  }, [pagos])

  const generateCorrelativo = (prevString)=> {
    // console.log("Enter Core rquie",prevString)
    let letters = '';
    let numbers = '';
    let finalLetter = '';
    const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const numbersList = '0123456789';

    if (prevString === undefined) {
        // console.log("nUN")
        letters = 'AAAA';
        numbers = '0000';
        finalLetter = 'A';
    } else {
        // console.log("SSSSSS")
        letters = prevString.substring(0, 4);
        numbers = prevString.substring(4, 8);
        finalLetter = prevString.charAt(8);
        finalLetter = alphabet.charAt((alphabet.indexOf(finalLetter) + 1) % alphabet.length);
        if (finalLetter === 'A') {
            numbers = (parseInt(numbers, 10) + 1).toString().padStart(4, '0');
            if (numbers === '10000') {
                letters = (alphabet.indexOf(letters[3]) + 1).toString().padStart(4, 'A');
            }
        }
    }
    // console.log("Requiem Star: ",letters + numbers + finalLetter)

    return letters + numbers + finalLetter;
  }

  function handleCheck(e, index) {
    const newPagos = [...pagos]
    // console.log("value check",e.target.checked)
    newPagos[index].aPagar = e.target.checked
    newPagos[index].abono = newPagos[index].SaldoDPPlocal.toFixed(2)
    setPagos(newPagos)
  }

  function handleAbono(monto, index) {
    if (monto > pagos[index].SaldoDPPlocal) {
      NotificationManager.info("El abono no puede ser mayor que el Saldo con DPP")
    } else {
      const newPagos = [...pagos]
      newPagos[index].abono = monto
      setPagos(newPagos)
    }
    
  }

  function handlePay(){
    if(montoCalcular===0){
      NotificationManager.warning("El monto total no debe ser 0")
      return
    }
    if(!monedaPago){
      NotificationManager.warning("Seleccione una moneda de pago")
      return
    }    
    setPaymentData({

      facturas:pagos.filter(x => x.aPagar),
      montoPagar:monedaPago==='USD'?montoCalcular*factorDolar:montoCalcular,
      monedaPago,
      correlativo: generateCorrelativo(correlativo),
      pais:cliente.paisCodigo,
      sociedadTitle:sociedadTitle
    })
    // console.log("facturas: ",pagos,moment(new Date()).format("YYYY-MM-DD"))
    
    setComponent("Pago")
  }

  const enviarSolicitud = async()=>{
    // console.log("Solicitud")
    
    setShow(true)
  }

  const getNumFactura = (no_Factura,cabecera,referencia)=>{
    //SV,HN,NI - Referencia
    //GT, CR, RD, PY, PA  - Texto Cabecera
    if(cliente.paisCodigo === "SV" || cliente.paisCodigo === "HN" || cliente.paisCodigo === "NI"){
      return referencia
    }else if(cliente.paisCodigo === "GT" || cliente.paisCodigo === "CR" || cliente.paisCodigo === "RD" || cliente.paisCodigo === "PY" || cliente.paisCodigo === "PA" ){
      return cabecera
    }else{
      return ""
    }
    
  }

  const getNumSerie = (no_Factura,cabecera,referencia)=>{
    //SV,HN,NI - Referencia
    //GT, CR, RD, PY, PA  - Texto Cabecera
    if(cliente.paisCodigo === "SV" || cliente.paisCodigo === "HN" || cliente.paisCodigo === "NI"){
      return cabecera
    }else if(cliente.paisCodigo === "GT" || cliente.paisCodigo === "CR" || cliente.paisCodigo === "RD" || cliente.paisCodigo === "PY" || cliente.paisCodigo === "PA" ){
      return referencia
    }else{
      return ""
    }
    
  }

  const formatMoneda = (moneda)=>{
    if(sociedad === "Y001" || sociedad === "Y002" || sociedad === "Y003"){
      
      return moneda.toLocaleString('de-DE', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
      
    }else{
      return moneda.toLocaleString('en-US', {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      });
    }

    


  }

  const loadPagos = async()=>{
    try {
      const body = {"pageNumber":1,"pageSize":50}
      const bearer = localStorage.getItem('bearer')
      const response = await axios.post(process.env.REACT_APP_BACKEND_URL + "crm/getPagos/" ,body,{
      headers: {
        Authorization: "Bearer " + "user.token",
        CRMtoken: bearer
      }
    })
    // console.log("pagos list: ",response)
    const pagosTemp = response.data.pagos.sort(function(a, b) {
      let dateA = new Date(a.FechaContabilizacion__c);
      let dateB = new Date(b.FechaContabilizacion__c);
      return dateB - dateA;
    });

    // console.log("pagos list comprobante: ",pagosTemp[0].Correlativo__c)
    setCorrelativo(pagosTemp[0].Correlativo__c)
      
    } catch (error) {
      
    }
  }

  return (
    <>
      <br />
      <h5 style={{ textAlign: 'center', fontWeight: 'bold' }}>Estado de Cuenta</h5>
      {loading ? 
        <div style={{textAlign:"center"}}>
          <CircularProgress />
        </div>
      : null}
      <Table striped bordered hover size="sm" responsive>
        <thead style={{ color: 'white', backgroundColor: '#00295E' }}>
          <tr>
            <th>Pagar</th>
            <th>No. factura</th>
            <th>Serie</th>
            <th>Fecha factura</th>
            <th>Vencimiento</th>
            <th>Saldo</th>
            <th>Saldo con DPP</th>
            <th>Importe a pagar</th>
          </tr>
        </thead>
        <tbody>
          
          {pagos.map((item, index) => (
            <tr key={index}>
              <td>
                <InputGroup>
                  <InputGroup.Checkbox onChange={e => handleCheck(e, index)} aria-label="Checkbox for following text input" />
                </InputGroup>
              </td>
              <td style={{ verticalAlign: 'middle' }}>{getNumFactura(item.No_Factura,item.Cabecera,item.Referencia)}</td>
              <td style={{ verticalAlign: 'middle' }}>{getNumSerie(item.No_Factura,item.Cabecera,item.Referencia)}</td>
              <td style={{ verticalAlign: 'middle', textAlign: 'right' }}>{moment(item.Fecha_Factura).format("DD/MM/YYYY")}</td>
              <td style={{ verticalAlign: 'middle', textAlign: 'right' }}>{moment(item.Fecha_Vencimiento).format("DD/MM/YYYY")}</td>
              <td style={{ verticalAlign: 'middle', textAlign: 'right' }}>{(cliente?.moneda??'')+" "+formatMoneda(item.SaldoLocal)}</td>
              <td style={{ verticalAlign: 'middle', textAlign: 'right' }}>{(cliente?.moneda??'')+" "+formatMoneda(item.SaldoDPPlocal)}</td>
              <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>
                {item.aPagar ?
                  <InputGroup size="sm">
                    <InputGroup.Text id="basic-addon1">{cliente?.moneda ?? '-'}</InputGroup.Text>
                    <Form.Control
                      style={{ width: '30px' }}
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      type='number'
                      value={item?.abono ?? item.SaldoDPPlocal.toFixed(2)}
                      onChange={e => handleAbono(e.target.value, index)}
                    />
                  </InputGroup> : '-'}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <InputGroup className="mb-3" >
        <InputGroup.Checkbox checked={monedaPago === cliente?.moneda}
          onChange={_ => setMonedaPago(cliente?.moneda ?? '-')}
          aria-label="Checkbox for following text input" />
        <InputGroup.Text id="basic-addon1" style={{ backgroundColor: '#00295E' }}>
          <a style={{ color: 'white', fontWeight: 'bold' }}>{cliente?.nombreMoneda ?? '-'} ({cliente?.moneda ?? '-'})</a>
        </InputGroup.Text>
        <FormControl disabled value={(cliente?.moneda ?? '-') + " " + (Number(montoCalcular).toFixed(2))} aria-label="Text input with checkbox" style={{ textAlign: 'right' }} />
      </InputGroup>
      <InputGroup  className={"mb-3 "+showDollar}>
        <InputGroup.Checkbox checked={monedaPago === 'USD'}
          onChange={_ => setMonedaPago('USD')}
          aria-label="Checkbox for following text input" />
        <InputGroup.Text id="basic-addon1" style={{ backgroundColor: '#00295E' }}>
          <a style={{ color: 'white', fontWeight: 'bold' }}>Dólares (USD)</a>
        </InputGroup.Text>
        <FormControl disabled value={"USD " + (factorDolar * montoCalcular).toFixed(2)} aria-label="Text input with checkbox" style={{ textAlign: 'right' }} />
      </InputGroup>
      <br />

      <div style={{ width: '100%', display: 'inline-block', textAlign: 'end' }}>
        <Button variant='secondary' onClick={() => navigate({
          pathname: '/home-agente'
        })} style={{ fontWeight: 'bolder', margin: '5px' }}>Regresar</Button>
        <Button style={{ backgroundColor: '#00B050', borderColor: '#00B050', fontWeight: 'bolder', margin: '5px' }}
          onClick={handlePay}
        >Pagar con tarjeta</Button>
        <Button onClick={() => setComponent("BancaElectronica")} style={{ backgroundColor: '#00B050', borderColor: '#00B050', fontWeight: 'bolder', margin: '5px' }}>
          Ir a banca electrónica</Button>
        <Button onClick={enviarSolicitud} style={{ backgroundColor: '#00B050', borderColor: '#00B050', fontWeight: 'bolder', margin: '5px' }}>
          Necesito que me contacten</Button>
      </div>
      <br />
      <br />
      <SolicitudContactoCliente show={show} setShow={setShow} cliente={cliente}/>
      
    </>
  )
}
