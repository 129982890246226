import React, { useState, useEffect } from 'react'
import { Container, Col, Row, Image } from 'react-bootstrap'
import { useParams, useNavigate } from "react-router-dom";

import LogoSolid from '../assets/GUATEMALA GRUPO SOLID/Logo Soli Blanco.png'
import LogoPaletaBlanco from '../assets/GUATEMALA GRUPO SOLID/Logo Paleta blanco.png'
import LogoSytecBlanco from '../assets/GUATEMALA GRUPO SOLID/Logo Sytec Blanco_h.png'
import LogoCorona from '../assets/GUATEMALA GRUPO SOLID/Logo Corona 2.png'
import LogoDuSolid from '../assets/GUATEMALA DUSOLID/Logo Dusolid Blanco.png'
import LogoFarbeBlanco from '../assets/GUATEMALA DUSOLID/Logo Farbe Blanco.png'
import LogoExpertBlanco from '../assets/GUATEMALA DUSOLID/Logo Expert Blanco.png'
import LogoLapco from '../assets/HONDURAS LAPCO/Logo Lapco-01.png'
import LogoModelo from '../assets/NICARAGUA MODELO/Logo Modelo_Blanco.png'

import EstadoCuenta from '../components/cliente/estadoCuenta'
import Pago from '../components/form/pago'
import Comprobante from '../components/cliente/comprobante'
import IrBancaElectronica from '../components/cliente/irBancaElectronica'
import Fin from '../components/cliente/fin'
import { NotificationManager } from 'react-notifications'
import axios from 'axios'
import BlurLoading from '../components/commons/loading'
import { memo } from "react";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

import sociedades from "../assets/sociedades.js"
import Pusher from 'pusher-js'



function PortalPagos() {

  const navigate = useNavigate()
  const [pagos, setPagos] = useState([])
  const [component, setComponent] = useState(<EstadoCuenta />)
  const [componentNombre, setComponentNombre] = useState('EstadoCuenta')
  const { idCliente } = useParams();
  const [token, setToken] = useState('')
  const [cliente, setCliente] = useState(null)
  const [loading, setLoading] = useState(false)
  const [showDbErrorMessage, setShowDbErrorMessage] = useState(false)  
  const [savingChangesDb, setSavingChangesDb] = useState(true)
  const [showDbSuccessMessage, setShowDbSuccessMessage] = useState(false)
  const [paymentData, setPaymentData] = useState({})
  const [dataPDFfin, setDataPDFfin] = useState({})

  const [factorDolar, setFactorDolar] = useState(0)
  const [sociedad,setSociedad] = useState("")
  const [sociedadTitle,setSociedadTitle] = useState("")
  const [sociedadesCliente,setSociedadesCliente] = useState([])

  const pusher = new Pusher('2864429795903f7b3a98', {
    cluster: 'us2',
    encrypted: true,
  })

  
  

  const endLoadingAnimationSuccess = (onSuccess = () => { }) => {
    setTimeout(() => {
      setShowDbSuccessMessage(true)
      setTimeout(() => {
        setSavingChangesDb(false)
        setShowDbSuccessMessage(false)
        onSuccess()
      }, 1500)
    }, 2000)
  }

  const endLoadingAnimationFailure = (onFailure = () => { }) => {
    setTimeout(() => {
      setShowDbErrorMessage(true)
      setTimeout(() => {
        setSavingChangesDb(false)
        setShowDbErrorMessage(false)
        onFailure()
      }, 1500)
    }, 2000)
  }

  async function getFacturacion() {
    const bearer = localStorage.getItem('bearer')
    const response = await axios.get(process.env.REACT_APP_BACKEND_URL + "crm/getFacturacion/" + cliente.Id, {
      headers: {
        Authorization: "Bearer " + "user.token",
        CRMtoken: bearer
      }
    })
    try {
      const responseMoney = await axios.get(process.env.REACT_APP_BACKEND_URL + "pay/getCurrencyFactor/" + cliente.moneda + "/USD", {
        headers: {
          Authorization: "Bearer " + "user.token",
          CRMtoken: bearer
        }
      })
      setFactorDolar(responseMoney.data.currencyFactor)
    } catch (e) {
      NotificationManager.info("Error obteniendo conversion para el dolar")
    }

    if (response.data.success) {
      const newFacturas = response.data.facturas.map(x => ({ ...x, abono: x.Saldo_DPP }))
      // console.log("Facturas: ",newFacturas,newFacturas.filter(item=>{item.Sociedad = sociedad}))        
      setPagos(newFacturas)
    } else {

    }
  }


  function getLogo(cl){
    // console.log("Cliente magistral",cl)
  }

  const Logo = ({}) => {
    // getFacturacion()
    
    var grupoCliente = ""
    if (cliente){
      grupoCliente = sociedad
      
    }

    
    

    

    if (grupoCliente === "G001" || grupoCliente === "D001" || grupoCliente === "G002"){
      return <Row>
        <Col xs={12}>
          <Image src={LogoSolid} style={{ marginTop: '-20px', marginBottom: '-20px' }} fluid />
        </Col>
        <Col xs={4}>
          <Image src={LogoPaletaBlanco} fluid />
        </Col>
        <Col xs={4}>
          <Image src={LogoSytecBlanco} fluid />
        </Col>
        <Col xs={4}>
          <Image src={LogoCorona} fluid />
        </Col>

      </Row>

    }else if(grupoCliente === "G600"){
      return <Row>
        <Col xs={12}>
          <Image src={LogoDuSolid} style={{ marginTop: '-20px', marginBottom: '-20px' }} fluid />
        </Col>
        <Col xs={6}>
          <Image src={LogoExpertBlanco} style={{marginTop:'20px'}} fluid />
        </Col>
        <Col xs={6}>
          <Image src={LogoFarbeBlanco} style={{marginTop:'30px'}} fluid />
        </Col>
        

      </Row>
      
    }else if(grupoCliente === "S001" || grupoCliente === "S002" || grupoCliente === "C001" || grupoCliente === "C002"){
      return <Row>
        <Col xs={12}>
          <Image src={LogoSolid} style={{ marginTop: '-20px', marginBottom: '-20px' }} fluid />
        </Col>
        <Col xs={6}>
          <Image src={LogoSytecBlanco} fluid />
        </Col>
        <Col xs={6}>
          <Image src={LogoCorona} style={{width:"80%"}} fluid />
        </Col>

      </Row>
    }else if(grupoCliente === "H001" || grupoCliente === "H002" || grupoCliente === "H101"){
      return <Row>
        <Col xs={12}>
          <Image src={LogoLapco} style={{ margin: '-15px 20px',width:'65%' }} fluid />
        </Col>
        <Col xs={6}>
          <Image src={LogoSytecBlanco} style={{marginTop:'10px'}} fluid />
        </Col>
        <Col xs={6}>
          <Image src={LogoCorona} style={{marginTop:'10px',width:"80%"}} fluid />
        </Col>

      </Row>
    }else if(grupoCliente === "N001" || grupoCliente === "P001"){
      return <Row>
        <Col xs={12}>
          <Image src={LogoSolid} style={{ marginTop: '-20px', marginBottom: '-20px' }} fluid />
        </Col>
        <Col xs={12}>
          <Image src={LogoCorona} style={{width:"40%",marginLeft:"30%",marginTop:"-10px"}} fluid />
        </Col>

      </Row>
    }else if(grupoCliente === "N002" || grupoCliente === "P003"){
      return <Row>
        <Col xs={12}>
          <Image src={LogoModelo} style={{ marginTop: '-20px', marginBottom: '-20px' }} fluid />
        </Col>
        <Col xs={12}>
          <Image src={LogoSytecBlanco} style={{width:"45%",marginLeft:"30%",marginTop:"10px"}} fluid />
        </Col>

      </Row>
    }else{
        return <Row>
          <Col xs={12}>
            <Image src={LogoSolid} style={{ marginTop: '-20px', marginBottom: '-20px' }} fluid />
          </Col>

        </Row>
      }
      
  }


  if (idCliente) getAuth()

  async function getAuth() {
    const idClienteLocal = localStorage.getItem('idCliente');
    const token = localStorage.getItem('token');
    if (idClienteLocal && token && idClienteLocal === idCliente) {
      setToken(token)
      return
    }
    try {
      const response = await axios.get(process.env.REACT_APP_BACKEND_URL + "crm/getAuth", {
        headers: {
          Authorization: "Bearer " + "user.token",
        }
      })
      // console.log("token",response.data)
      const token = response.data.access_token
      if(!token){
        endLoadingAnimationFailure(() => {
          NotificationManager.error('Ocurrió un error al autenticar el usuario.')
          navigate({
            pathname: '/landing'
          })
        })    
      }
      
      localStorage.setItem('bearer', token);
      localStorage.setItem('idCliente', idCliente);
      setToken(token)
    } catch (error) {
      endLoadingAnimationFailure(() => {
        NotificationManager.error('Ocurrió un error al autenticar el usuario.')
        navigate({
          pathname: '/landing'
        })
      })      
    }
  }

  const handleChangeSociedad = (event, newValue) => {
    setSociedad(newValue.id);
  };

  useEffect(() => {
    getLogo(cliente)
    if(cliente?.Id) endLoadingAnimationSuccess()
    // console.log("cliente: ",cliente)

    
    
  }, [cliente])
  

  useEffect(() => {
    async function getAccount() {
      try {
        let idTemp = idCliente
        if(idTemp.length === 9){
          idTemp = "0" + idTemp
        }

        const response = await axios.get(process.env.REACT_APP_BACKEND_URL + "crm/getAccount/" + idTemp, {
          headers: {
            Authorization: "Bearer " + "user.token",
            CRMtoken: token
          }
        })
        const user = response.data
        // console.log("User Magistral: ",response)
        if(!user?.Id){
          endLoadingAnimationFailure(() => {
            NotificationManager.error('Código de cliente no encontrado.')
            navigate({
              pathname: '/landing'
            })
          }) 
        }else{          
          setCliente(user)
          setSociedad(user?.Sociedades[0].Sociedad__c)
          setSociedadesCliente(user?.Sociedades.map(item =>({label:item.Sociedad__c + " - " +sociedades[item.Sociedad__c],id:item.Sociedad__c})))
        }        
      } catch (error) {
        endLoadingAnimationFailure(() => {
          NotificationManager.error('Ocurrió un error al obtener datos del cliente.')
          navigate({
            pathname: '/landing'
          })
        })        
      }
    }
    if (token && !cliente?.Id && idCliente && navigate){
      getAccount()
      
    }

    if(cliente){
      // getFacturacion()
      console.log("Pagos ",pagos)
    }
    
  }, [token, cliente, idCliente, navigate])

 



  function handleSetComponent(nombre) {
    switch (nombre) {
      case 'EstadoCuenta':
        setComponent(<EstadoCuenta cliente={cliente} sociedad={sociedad} sociedadTitle={sociedadTitle} setPaymentData={setPaymentData} setComponent={setComponentNombre} />)
        break;
      case 'Pago':
        setComponent(<Pago paymentData={paymentData} setPaymentData={setPaymentData} setComponent={setComponentNombre} pusher={pusher}/>)
        break;
      case 'Comprobante':
        setComponent(<Comprobante setDataPDFfin={setDataPDFfin} cliente={cliente} paymentData={paymentData} setPaymentData={setPaymentData} setComponent={setComponentNombre} pusher={pusher} />)
        break;
      case 'Fin':
        setComponent(<Fin cliente={cliente} setComponent={setComponentNombre} dataPDFfin={dataPDFfin} />)
        break;
      case 'BancaElectronica':
        setComponent(<IrBancaElectronica setComponent={setComponentNombre} clientePais={cliente.Pais__c} />)
        break;
      default:
        break;
    }
  }

  useEffect(() => {
    handleSetComponent(componentNombre)
  }, [componentNombre, cliente,sociedad,paymentData,sociedadTitle])

  useEffect(()=>{
    if(sociedad){
      setSociedadTitle(sociedad + " - " + sociedades[sociedad])
    } 
    
  },[sociedad])

 

  return (
    <div style={{ background: '#003E83', minHeight: '100vh', width: '100%', padding: '20px', overflow: 'auto' }}>
      <BlurLoading savingChangesDb={savingChangesDb}
            showDbSuccessMessage={showDbSuccessMessage} showDbErrorMessage={showDbErrorMessage} />
      <Container>
        <Row style={{ display: 'flex', alignItems: 'center' }}>
          <Col xs={{span:6,order:0}} sm={{span:6,order:0}} md={{span:2,order:0}} lg={{span:2,order:0}}>
            <Logo/>
          </Col>
          <Col xs={{span:6,order:1}} sm={{span:6,order:1}} md={{span:8,order:1}} lg={{span:8,order:1}} style={{ color: 'white' }}>
            <h4 style={{ textAlign: 'center', fontWeight: 'bold' }}>PORTAL DE PAGOS</h4>
            <h6 style={{ textAlign: 'center' }}>{sociedadTitle}</h6>
          </Col>
        </Row>
      </Container>
      <br />
      <Container style={{ backgroundColor: '#00295E', color: 'white' }}>
        
        <div style={{ padding: '20px' }}>
          
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <a style={{ fontWeight: 'bolder' }}>
              No. cliente
            </a>
            <a>
              {cliente?.KUNNR__c || ''}
            </a>
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <a style={{ fontWeight: 'bolder' }}>
              Nombre cliente
            </a>
            <a>
              {cliente?.Name || ''}
            </a>
          </div>
          <br/>
          {componentNombre === "EstadoCuenta" ?
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <a style={{ fontWeight: 'bolder' }}>
                Sociedad
              </a>
              <div style={{background:"white"}}>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={sociedadesCliente}
                  sx={{ width: 150,color:"black",height:40 }}
                  size="small"
                  value={sociedad}
                  onChange={handleChangeSociedad}
                  renderInput={(params) => <TextField {...params} />}
                />
              </div>
            </div>
            :
            null
          }
          
          
        </div>
      </Container>
      <Container key={componentNombre} style={{ backgroundColor: 'white' }}>
        {component}
      </Container>
    </div>
  )
}

export default memo(PortalPagos)