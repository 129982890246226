import React from 'react';
import './index.css';
import App from './App';
import { render } from "react-dom";
import reportWebVitals from './reportWebVitals';
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate
} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-notifications/lib/notifications.css';
import Login from './views/login'
import Home from './views/home'
import Landing from './views/landing'
import PortalPagos from './views/portalPagos'
import TerminosCondiciones from './views/terminosCondiciones'
import PortalAgente from './views/portalAgente';
import { NotificationContainer } from 'react-notifications';


const rootElement = document.getElementById("root");

render(
  <BrowserRouter>
    <NotificationContainer />
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="login" element={<Login />} />
      <Route path="landing" element={<Landing />} />
      <Route path="conduct" element={<Landing />} />
      <Route path="portal-pagos/:idCliente" element={<PortalPagos />} />
      <Route path="terminos-y-condiciones" element={<TerminosCondiciones />} />
      <Route path="portal-agente" element={<PortalAgente />} />
      <Route path="/*" element={<Navigate replace to="/login" />} />
    </Routes>
  </BrowserRouter>
  ,
  rootElement
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
