import React,{useState} from 'react'
import { Button, Col, Row, Table } from 'react-bootstrap'
import GenerarPDF from '../../utils/generarPDF'
import ReactDOMServer from 'react-dom/server'
import SolicitudContactoCliente from './solicitudContactoCliente'

export default function Fin({cliente, setComponent, dataPDFfin }) {

  console.log('dataPDFfin', dataPDFfin)
  const [show, setShow] = useState(false);

  const getNumFactura = (cabecera,referencia)=>{
    //SV,HN,NI - Referencia
    //GT, CR, RD, PY, PA  - Texto Cabecera
    if(cliente.paisCodigo === "SV" || cliente.paisCodigo === "HN" || cliente.paisCodigo === "NI"){
      return referencia
    }else if(cliente.paisCodigo === "GT" || cliente.paisCodigo === "CR" || cliente.paisCodigo === "RD" || cliente.paisCodigo === "PY" || cliente.paisCodigo === "PA" ){
      return cabecera
    }else{
      return ""
    }
    
  }

  const getNumSerie = (cabecera,referencia)=>{
    //SV,HN,NI - Referencia
    //GT, CR, RD, PY, PA  - Texto Cabecera
    if(cliente.paisCodigo === "SV" || cliente.paisCodigo === "HN" || cliente.paisCodigo === "NI"){
      return cabecera
    }else if(cliente.paisCodigo === "GT" || cliente.paisCodigo === "CR" || cliente.paisCodigo === "RD" || cliente.paisCodigo === "PY" || cliente.paisCodigo === "PA" ){
      return referencia
    }else{
      return ""
    }
    
  }

  const getAddress = (sociedad)=>{
    if(sociedad === "N002"){
      return ["RUC: J0310000009074","Oficina Central","Carretera a Masaya Km 13.5","Teléfono: 2279-9074"]
    }else if(sociedad === "G001"){
      return ["12 calle 1-25 zona 10 Edificio Géminis 10 Torre Sur Nivel 18","Oficina 1802, Guatemala, Guatemala","NIT: 549234-3","PBX: 2320-6264","servicioalclientecorona@gruposolid.com"]
    }else if(sociedad === "H001"){
      return ["OFICINA PRINCIPAL. KM. 10, BÚFALO VILLANUEVA,","CORTES PBX: (504) 2270-6001 HONDURAS, C.A.","RTN: 05019001062297","www.gruposolidca.com, www.pinturascorona.com","email: lapcohonduras@gruposolid.com"]
    }else if(sociedad === "N001"){
      return ["RUC: J0310000001596","Oficina Central","Km 13.7 Carretera a Masaya","Teléfono: 2276-8090"]
    }else if(sociedad === "P001"){
      return ["Ave. José María Torrijos Las Mañanitas,","Tocumen, Plaza Las Mañanitas frente a","la barriada Los Nogales Local 4","pinturascoronapa@gruposolid.com","RUC: 61640-33-349863 DV 33","TEL: 229-8373"]
    }else if(sociedad === "S001"){
      return ["Km. 11 1/2, Carretera Troncal del Norte, frente","a Paso a Desnivel, Apopa, San Salvador.","PBX: 2327-5600","NRC: 224-0","NIT: 0614-121181-002-3"]
    }else if(sociedad === "C001"){
      return ["Ident. Jurídica: 3-101-131155","Teléfono: +(506) 4404-1160","Dirección: Costado Sur de EPA en Colima","Condominio Condal Tibás, Bodegas Local 63,","Oficinas Local 27.."]
    }else{
      return [""]
    }
  }
  
  async function handlePDF() {
    const PDFcomponent = <>
      <head><link href="https://fonts.cdnfonts.com/css/gotham" rel="stylesheet" /></head>
      <body>
        <Row >
          <Col xs={12} md={2} />

          <Col xs={12} md={8} >

            <br />
            <div style={{ border: '3px solid black', padding: '30px' }}>
              <div style={{ textAlign: 'center' }}>
                <h4 style={{ fontWeight: 'bold' }}>{dataPDFfin.comprobante.sociedadTitle}</h4>
                <br />
                {dataPDFfin.comprobante.direccion.map((x) => {
                  return <>
                      {x} <br/>
                    </>
                })}
              </div>
              <br />
              <Table style={{ width: '100%', border: '0px solid white' }} responsive size="sm">
                <tbody >
                  <tr>
                    <td style={{ fontWeight: 'bold' }}>Impreso el:</td>
                    <td style={{ textAlign: 'right', }}>{dataPDFfin.comprobante.fechaImpreso}</td>
                  </tr>
                  <tr>
                    <td style={{ fontWeight: 'bold' }}>Usuario:</td>
                    <td style={{ textAlign: 'right', }}>{dataPDFfin.comprobante.usuario}</td>
                  </tr>
                  <tr>
                    <td style={{ fontWeight: 'bold' }}>Correlativo:</td>
                    <td style={{ textAlign: 'right', }}>{dataPDFfin.comprobante.correlativo}</td>
                  </tr>
                </tbody>
              </Table>
              <br />
              <h4 style={{ fontWeight: 'bold', textAlign: 'center' }}>AUTORIZACIÓN DE PAGO</h4>
              <br />
              <Table style={{ width: '100%', border: '0px solid white' }} responsive size="sm">
                <tbody >
                  <tr>
                    <td style={{ fontWeight: 'bold' }}>Fecha:</td>
                    <td style={{ textAlign: 'right', }}>{dataPDFfin.comprobante.fechaPago}</td>
                  </tr>
                  <tr>
                    <td style={{ fontWeight: 'bold' }}>Identificador:</td>
                    <td style={{ textAlign: 'right', }}>{dataPDFfin.comprobante.identificador}</td>
                  </tr>
                  <tr>
                    <td style={{ fontWeight: 'bold' }}>No. de Autorización:</td>
                    <td style={{ textAlign: 'right', }}>{dataPDFfin.backendData.AuthorizationCode}</td>
                  </tr>
                  <tr>
                    <td style={{ fontWeight: 'bold' }}>Código cliente:</td>
                    <td style={{ textAlign: 'right', }}>{dataPDFfin.comprobante.codigoCliente}</td>
                  </tr>
                  <tr>
                    <td style={{ fontWeight: 'bold' }}>Nombre:</td>
                    <td style={{ textAlign: 'right', }}>{dataPDFfin.comprobante.nombre}</td>
                  </tr>
                </tbody>
              </Table>
              <br />
              <h4 style={{ fontWeight: 'bold', textAlign: 'center' }}>DOCUMENTOS ABONADOS</h4>
              <Table style={{ width: '100%', border: '0px solid white' }} responsive size="sm">
                <tbody>
                  {dataPDFfin.comprobante.documentosAbonados.map((item, index) => (
                    <tr key={index}>


                      <td>{getNumFactura(item.Cabecera,item.Referencia)}</td>
                      <td style={{ textAlign: 'center' }}>{getNumSerie(item.Cabecera,item.Referencia)}</td>   


                      {/* <td>{item.No_Factura}</td>
                      <td style={{ textAlign: 'center' }}>{item.Serie}</td> */}
                      <td style={{ textAlign: 'right' }}>{dataPDFfin.paymentData.monedaPago + ' ' + Number(item.abono).toFixed(2)}</td>
                    </tr>
                  ))}
                  <tr>
                    <td />
                    <td style={{ textAlign: 'right', fontWeight: 'bold' }}>Total:</td>
                    <td style={{ textAlign: 'right', }}>{dataPDFfin.paymentData.monedaPago} {Number(dataPDFfin.paymentData.montoPagar).toFixed(2)}</td>
                  </tr>
                </tbody>
              </Table>
              <br />
              <h4 style={{ fontWeight: 'bold', textAlign: 'center' }}>MEDIOS DE PAGO</h4>
              <Table style={{ width: '100%', border: '0px solid white' }} responsive size="sm">
                <tbody >
                  {dataPDFfin.comprobante.mediosPago.map((item, index) => (
                    <tr key={index}>
                      <td>{item.medio}</td>
                      <td style={{ textAlign: 'right' }}>{item.monto}</td>
                    </tr>
                  ))}
                  <tr>
                    <td style={{ textAlign: 'right', fontWeight: 'bold' }}>Total:</td>
                    <td style={{ textAlign: 'right', }}>{dataPDFfin.paymentData.monedaPago} {Number(dataPDFfin.paymentData.montoPagar).toFixed(2)}</td>
                  </tr>
                </tbody>
              </Table>
              <br />
              <br />
              <a>*Este no es un recibo oficial de caja.</a>
              <div>www.gruposolidca.com</div>
            </div>
            <br />
          </Col>
        </Row>
      </body>
    </>
    const htmlString = '<!DOCTYPE html><html><link href="https://fonts.cdnfonts.com/css/gotham" rel="stylesheet">' + ReactDOMServer.renderToString(PDFcomponent) + '</html>'
    await GenerarPDF(htmlString, () => { })
  }

  const enviarSolicitud = async()=>{
    console.log("Solicitud")
    
    setShow(true)
  }

  return (
    <>
      <br />
      <br />
      <br />
      <br />
      <h1 style={{ textAlign: 'center', fontWeight: 'bold' }}>¡Gracias por preferirnos!</h1>
      <br />
      <br />
      <br />
      <br />

      {/* <div style={{ width: '100%', display: 'flex', justifyContent: 'end' }}>
        <Button onClick={handlePDF} variant='primary' style={{ fontWeight: 'bolder', margin: '5px' }}>Ver comprobante en PDF</Button>
        <Button onClick={() => setComponent("EstadoCuenta")} variant='secondary' style={{ fontWeight: 'bolder', margin: '5px' }}>Regresar</Button>
        <Button onClick={enviarSolicitud} style={{ backgroundColor: '#00B050', borderColor: '#00B050', fontWeight: 'bolder', margin: '5px' }}>Necesito que me contacten</Button>
        <Button onClick={() => setComponent("BancaElectronica")} style={{ backgroundColor: '#00B050', borderColor: '#00B050', fontWeight: 'bolder', margin: '5px' }}>Ir a banca electrónica</Button>
      </div> */}

      <Row>
        <Col xs={6} sm={6} md={3} style={{textAlign:"center"}}><Button onClick={handlePDF} variant='primary' style={{ fontWeight: 'bolder',margin:"10px" }}>Ver comprobante en PDF</Button></Col>
        <Col xs={6} sm={6} md={3} style={{textAlign:"center"}}><Button onClick={() => setComponent("EstadoCuenta")} variant='secondary' style={{ fontWeight: 'bolder',margin:"10px" }}>Regresar</Button></Col>
        <Col xs={6} sm={6} md={3} style={{textAlign:"center"}}><Button onClick={enviarSolicitud} style={{ backgroundColor: '#00B050', borderColor: '#00B050', fontWeight: 'bolder',margin:"10px"  }}>Necesito que me contacten</Button></Col>
        <Col xs={6} sm={6} md={3} style={{textAlign:"center"}}><Button onClick={() => setComponent("BancaElectronica")} style={{ backgroundColor: '#00B050', borderColor: '#00B050', fontWeight: 'bolder',margin:"10px" }}>Ir a banca electrónica</Button></Col>
      </Row>

      <br />
      <br />
      <SolicitudContactoCliente show={show} setShow={setShow} cliente={cliente}/>
    </>
  )
}
