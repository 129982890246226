import React, { useState, useEffect } from 'react'
import { InputGroup, FormControl, Container, Button, Col, Row, Image, Tab, Tabs } from 'react-bootstrap'
import { useParams, useNavigate } from "react-router-dom";
import LogoSolid from '../assets/GUATEMALA GRUPO SOLID/Logo Soli Blanco.png'
import { NotificationManager } from 'react-notifications'
import axios from 'axios'
import BlurLoading from '../components/commons/loading'
import { memo } from "react";
import ReportePago from '../components/agente/reportePago';
import HomeAgente from '../components/agente/homeAgente';
import SolicitudContacto from '../components/agente/solicitudContacto';
import '../assets/styles/tabs.css'
import Reembolso from '../components/agente/reembolso';


function PortalPagos() {

  const navigate = useNavigate()
  const [componentNombre, setComponentNombre] = useState('EstadoCuenta')
  const { idCliente } = useParams();
  const [token, setToken] = useState('')
  const [cliente, setCliente] = useState(null)
  const [loading, setLoading] = useState(false)
  const [showDbErrorMessage, setShowDbErrorMessage] = useState(false)
  const [savingChangesDb, setSavingChangesDb] = useState(false)
  const [showDbSuccessMessage, setShowDbSuccessMessage] = useState(false)
  const [paymentData, setPaymentData] = useState({})
  const [idClienteBuscar, setIdClienteBuscar] = useState('')
  const [listaSolicitudes,setListaSolicitudes] = useState([])
  const [listaTopSolicitudes,setListaTopSolicitudes] = useState([])
  const [pagos,setPagos] = useState([])
  const [pageSize,setPageSize] = useState(50)
  const rol = localStorage.getItem("rolsolid")

  const endLoadingAnimationSuccess = (onSuccess = () => { }) => {
    setTimeout(() => {
      setShowDbSuccessMessage(true)
      setTimeout(() => {
        setSavingChangesDb(false)
        setShowDbSuccessMessage(false)
        onSuccess()
      }, 1500)
    }, 2000)
  }

  const endLoadingAnimationFailure = (onFailure = () => { }) => {
    setTimeout(() => {
      setShowDbErrorMessage(true)
      setTimeout(() => {
        setSavingChangesDb(false)
        setShowDbErrorMessage(false)
        onFailure()
      }, 1500)
    }, 2000)
  }

  
  const solicitudesTop = (solicitudes) => {
    
    const sortSolicitudes = solicitudes.sort((a, b) => {
      if (a.createdAt > b.createdAt) {
        return 1;
      }
      if (a.createdAt < b.createdAt) {
        return -1;
      }
      return 0;
    })

    setListaTopSolicitudes(sortSolicitudes.slice(0, 9))
    
  }
  
  const getSolicitudes = async() =>{
    
    try {
      const response = await axios.get(process.env.REACT_APP_BACKEND_URL + "solicitud/getSolicitudes/")
      setListaSolicitudes(response.data.solicitudes)
      solicitudesTop(response.data.solicitudes)
    

    } catch (error) {
      NotificationManager.error("Error al enviar solicitud")
    }
    
  }


  const loadPagos = async(pageSizeTemp)=>{
    setLoading(true)
    try {
      const body = {"pageNumber":1,"pageSize":pageSizeTemp}
      const bearer = token
      const response = await axios.post(process.env.REACT_APP_BACKEND_URL + "crm/getPagos/",body, {
      headers: {
        Authorization: "Bearer " + "user.token",
        CRMtoken: bearer
      }
    })
    const pagosTemp = response.data.pagos

    console.log("pagos list: ",pagosTemp)


    setPagos(pagosTemp)
      
    } catch (error) {
      
    }
    setLoading(false)
  }

  const loadMoreData = ()=>{
    loadPagos(pageSize + 50)
    setPageSize(pageSize + 50)

  }

  async function getAuth() {
    const idClienteLocal = localStorage.getItem('idCliente');
    const token = localStorage.getItem('token');
    if (idClienteLocal && token && idClienteLocal === idCliente) {
      setToken(token)
      return
    }
    try {
      const response = await axios.get(process.env.REACT_APP_BACKEND_URL + "crm/getAuth", {
        headers: {
          Authorization: "Bearer " + "user.token",
        }
      })
      // console.log("token",response.data)
      const token = response.data.access_token
      if(!token){
        endLoadingAnimationFailure(() => {
          NotificationManager.error('Ocurrió un error al autenticar el usuario.')
          navigate({
            pathname: '/landing'
          })
        })    
      }
      
      localStorage.setItem('bearer', token);
      localStorage.setItem('idCliente', idCliente);
      setToken(token)
    } catch (error) {
      endLoadingAnimationFailure(() => {
        NotificationManager.error('Ocurrió un error al autenticar el usuario.')
        navigate({
          pathname: '/landing'
        })
      })      
    }
  }

  useEffect(() => {
    getAuth()
    getSolicitudes()
    console.log("rol",rol)
    
    
  }, [])

  useEffect(()=>{
    if(token){
      loadPagos(pageSize)
    }
    
  },[token])

  

  return (
    <div style={{ background: '#003E83', minHeight: '100vh', width: '100%', padding: '20px', overflow: 'auto' }}>
      <BlurLoading savingChangesDb={savingChangesDb}
        showDbSuccessMessage={showDbSuccessMessage} showDbErrorMessage={showDbErrorMessage} />
      <Container>
        <Row style={{ display: 'flex', alignItems: 'center' }}>
          <Col xs={2}>
            <Row>
              <Col xs={12}>
                <Image src={LogoSolid} style={{ marginTop: '-20px', marginBottom: '-20px' }} fluid />
              </Col>
            </Row>
          </Col>
          <Col xs={8} style={{ color: 'white' }}>
            <h4 style={{ textAlign: 'center', fontWeight: 'bold' }}>PORTAL DE PAGOS</h4>
            <h6 style={{ textAlign: 'center' }}>{rol}</h6>
          </Col>
        </Row>
      </Container>
      <br />
      <Container>
        <div style={{ background: 'white' }}>
          <Tabs
            defaultActiveKey="inicio"
            id="uncontrolled-tab-example"
            className="mb-3"
            fill
          >
            <Tab eventKey="inicio" title="Inicio">
              <Container style={{ color: 'white' }}>
                <div style={{ padding: '20px' }}>
                  <HomeAgente solicitudes={listaTopSolicitudes.slice(0, 10)} pagos={pagos.slice(0, 10)}/>
                </div>
              </Container>
            </Tab>
            <Tab eventKey="reportePagos" title="Reporte de pagos">
              <Container style={{ color: 'white' }}>
                {loading ? (<>
                  <div className="spinner-border loader-carga-horas" role="status">
                    <span className="visually-hidden"></span>
                  </div>
                </>):(
                  <div style={{ padding: '20px' }}>
                    <ReportePago pagos={pagos} loadMoreData={loadMoreData}/>
                  </div>
                )}
                
              </Container>
            </Tab>
            <Tab eventKey="consultaCliente" title="Consulta de cliente" >

              <div style={{ padding: '20px', paddingTop:'10%',paddingBottom:'10%' }}>
                <InputGroup className="mb-3">
                  <FormControl
                    placeholder='Ingrese su código de cliente'
                    aria-label="Example text with button addon"
                    aria-describedby="basic-addon1"
                    value={idClienteBuscar}
                    onChange={e => setIdClienteBuscar(e.target.value)}
                  />
                  <Button variant="outline-secondary" id="button-addon1"
                    onClick={() => {
                      navigate({
                        pathname: '/portal-pagos/' + idClienteBuscar,
                      })
                    }}
                    style={{
                      backgroundColor: '#00295E',
                      color: 'white'
                    }}
                  >
                    Ingresar
                  </Button>
                </InputGroup>
              </div>
            </Tab>
            <Tab eventKey="solicitudesContacto" title="Solicitudes de contacto" >
              <Container style={{ backgroundColor: 'white', color: 'black' }}>
                <div style={{ padding: '20px' }}>
                    <SolicitudContacto solicitudes={listaSolicitudes}/>
                </div>
              </Container>
            </Tab>
            {rol === "Supervisor" && 
            <Tab eventKey="reembolso" title="Reembolso" >
              <Container style={{ backgroundColor: 'white', color: 'black' }}>
                <div style={{ padding: '20px' }}>
                    <Reembolso  pagos={pagos} loadPagos={loadPagos}/>
                </div>
              </Container>
            </Tab>

            }
            
          </Tabs>
        </div>
      </Container>
    </div>
  )
}

export default memo(PortalPagos)