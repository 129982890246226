import React from 'react'
import { Button,Image } from 'react-bootstrap'
import { useNavigate } from "react-router-dom";
import LogoBi from "../../assets/BANCOS/Logo-Bi.png"
import LogoBanrural from "../../assets/BANCOS/Logo-Banrural.png"
import LogoDavivienda from "../../assets/BANCOS/Logo-Davivienda.png"
import LogoBancoAtlantida from "../../assets/BANCOS/Logo-BancoAtlantida.png"
import LogoBacCredomatic from "../../assets/BANCOS/Logo-BacCredomatic.png"
import LogoBancoLafise from "../../assets/BANCOS/Logo-BancoLafise.png"
import LogoBancoGeneral from "../../assets/BANCOS/Logo-BancoGeneral.png"

export default function EstadoCuenta({ setComponent,clientePais }) {
  
  const Bancos = ({}) => {
    if(clientePais === "ES - GT - Guatemala"){
      return (
        <>
          <Button href="https://www.bienlinea.bi.com.gt/" target='_blank' style={{backgroundColor:"white",borderColor:"white"}}>
            <Image src={LogoBi} fluid /> 
          </Button>
          <br/><br/><br/>
          <Button href="https://www.banrural.com.gt/cb/pages/jsp-ns/login-cons.jsp" target='_blank' style={{backgroundColor:"white",borderColor:"white"}}>
             <Image src={LogoBanrural} style={{width:"60%"}} fluid />
          </Button>
          <br/><br/><br/>
        </>
      )
    }else if(clientePais === "ES - SV - El Salvador"){
      return (
        <>
            <Button href="https://www.davivienda.com/wps/portal/personas/nuevo" target='_blank' style={{backgroundColor:"white",borderColor:"white"}}>
              <Image src={LogoDavivienda} style={{width:"80%"}} fluid /> 
            </Button>
            <br/><br/><br/>
        </>
      )
  
    }else if(clientePais === "ES - HN - Honduras"){
      return (
        <>
            <Button href="https://www.bancatlan.hn/" target='_blank' style={{backgroundColor:"white",borderColor:"white"}}>
              <Image src={LogoBancoAtlantida} style={{width:"100%"}} fluid /> 
            </Button>
            <br/><br/><br/>
        </>
      )
    }else if(clientePais === "ES - CR - Costa Rica"){
      return (
        <>
            <Button href="https://www.baccredomatic.com/es-cr" target='_blank' style={{backgroundColor:"white",borderColor:"white"}}>
              <Image src={LogoBacCredomatic} style={{width:"75%"}} fluid /> 
            </Button>
            <br/><br/><br/>
        </>
      )
    }else if(clientePais === "ES - NI - Nicaragua"){
      return (
        <>
            <Button href="https://www.baccredomatic.com/es-ni" target='_blank' style={{backgroundColor:"white",borderColor:"white"}}>
              <Image src={LogoBacCredomatic} style={{width:"75%"}} fluid /> 
            </Button>
            <br/><br/><br/>
            <Button href="https://secure.bancolafise.com.ni/BancanetLogin/WebForms/inicio.aspx" target='_blank' style={{backgroundColor:"white",borderColor:"white"}}>
              <Image src={LogoBancoLafise} style={{width:"70%"}} fluid /> 
            </Button>
            <br/><br/><br/>
        </>
      )
    }else if(clientePais === "ES - PA - Panamá"){
      return (
        <>
            <Button href="https://www.bgeneral.com/" target='_blank' style={{backgroundColor:"white",borderColor:"white"}}>
              <Image src={LogoBancoGeneral} style={{width:"75%"}} fluid /> 
            </Button>
            <br/><br/><br/>
            
        </>
      )
    }else{
      return(<><div></div></>)
    }
  }
  

    return (
      <>
        <br />
        <h5 style={{ textAlign: 'center', fontWeight: 'bold' }}>Bancas electrónicas</h5>
        <div style={{textAlign:'center'}}>
          <Bancos/>
        </div>
        <div style={{ width: '100%', display: 'inline-block', textAlign: 'end' }}>
          <Button variant='secondary' onClick={() => setComponent("EstadoCuenta")} style={{ fontWeight: 'bolder', margin: '5px' }}>Regresar</Button>
          <Button style={{
            backgroundColor: '#00B050', borderColor: '#00B050',
            fontWeight: 'bolder', margin: '5px'
          }}>
            Necesito que me contacten</Button>
        </div>
        <br />
        <br />
      </>
    )

  
}
