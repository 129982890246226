import React, { useRef, useEffect, useState } from 'react'
import { Col, Form } from 'react-bootstrap'

export default function InputExpirationDate({ newData, setNewData, setErrorParent = () => { } }) {
  const [date, setDate] = useState();
  const [typed, setTyped] = useState(false);
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState(' ')
  const inputDate = useRef();

  const zeroPad = (num) => {
    return num.toString().padStart(2, "0");
  }


  const handleChange = (e) => {
    const cardValue = inputDate.current.value
      .replace(/\D/g, '')
      .match(/(\d{0,2})(\d{0,2})/);
    var val = cardValue[1];    
    for (var i = 2; i <= 2; i++) {
      if (cardValue[i])
        val += ` / ${cardValue[i]}`;
    }
    inputDate.current.value = val;
    const numbers = inputDate.current.value.replace(/(\D)/g, '')
    setDate(numbers);
    setNewData({ ...newData, fechaExpiracion: `${numbers.substring(2)}${numbers.substring(0, 2)}` })
  }

  useEffect(() => {
    handleChange();
    if (typed) {
      if (date === '') {
        setError(true)
        setErrorParent(true);
        setErrorMsg('Fecha vacía')
      } else if (parseInt(date.substring(0, 1)) === 0 && parseInt(date.substring(1, 2) ?? 0) === 0) {
        setError(true)
        setErrorParent(true);
        setErrorMsg('Mes inválido');
      } else if (parseInt(date.substring(0, 1)) === 1 && parseInt(date.substring(1, 2) ?? 0) > 2) {
        setError(true)
        setErrorParent(true);
        setErrorMsg('Mes inválido');
      } else if (parseInt(date.substring(0, 1)) > 1 && date.substring(1, 2)) {
        setError(true)
        setErrorParent(true);
        setErrorMsg('Mes inválido');
      } else if (date.substring(3) && parseInt(date.substring(2)) < parseInt((new Date().getFullYear().toString()).substr(-2))) {
        setError(true)
        setErrorParent(true);
        setErrorMsg('Fecha previa');
      } else if (date.length === 4 && parseInt(`${date.substring(2)}${date.substring(0, 2)}`) <= parseInt(`${(new Date().getFullYear().toString()).substr(-2)}${zeroPad(new Date().getMonth() + 1)}`)) {
        setError(true)
        setErrorParent(true);
        setErrorMsg('Fecha previa');
      } else {
        setError(false);
        setErrorParent(false);
        setErrorMsg(' ')
      }
    }
  }, [date]);

  return (
    <Form.Group as={Col} controlId="test1">
      <Form.Label>Expiración</Form.Label>
      <Form.Control required type="input" placeholder='MM / YY'
        style={{ borderLeft: '10px solid #00295E' }}
        ref={inputDate}
        onChange={() => { handleChange(); setTyped(true) }} />
      <Form.Text style={{ color: 'red' }}>
        {errorMsg ?? ''}
      </Form.Text>
    </Form.Group>
  )
}
