import React, { useState, useEffect } from 'react'
import { useNavigate  } from "react-router-dom";
import EstadoCuenta from '../components/cliente/estadoCuenta';


export default function Home() {
  const navigate = useNavigate ()
  const [component, setComponent] = useState(<a></a>)
  
  useEffect(() => {
    const user = /*localStorage.getItem("@user_info")*/null
    if(!user){
      navigate({
        pathname:'/landing'
      })
    }
  }, [])

  function handleLogout(e){
    e.preventDefault()
    localStorage.removeItem("@user_info")
    navigate({
      pathname:'/login'
    })
  }
  
  return (
    <>
      {component}
    </>
  )
}
