import React, { useRef, useEffect, useState } from 'react'
import { Col, Form } from 'react-bootstrap'

export default function InputCard({ newData, setNewData, setErrorParent = () => { } }) {
  const [card, setCard] = useState(null);
  const [typed, setTyped] = useState(false);
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState(' ')

  const inputCard = useRef();

  const handleChange = () => {
    const cardValue = inputCard.current.value
      .replace(/\D/g, '')
      .match(/(\d{0,4})(\d{0,4})(\d{0,4})(\d{0,4})/);
    var val = cardValue[1];    
    for (var i = 2; i <= 4; i++) {
      if (cardValue[i])
        val += ` ${cardValue[i]}`;
    }
    inputCard.current.value = val;
    const numbers = inputCard.current.value.replace(/(\D)/g, '')
    setCard(numbers);
    setNewData({ ...newData, numeroTarjeta: numbers })
  }

  useEffect(() => {
    handleChange();
    if (typed) {
      if (card === '') {
        setError(true);
        setErrorParent(true);
        setErrorMsg('El número no debe estar vacío')
      } 
      // else if (card.substring(0, 1) !== '4' && card.substring(0, 1) !== '5') {
      //   setError(true);
      //   setErrorParent(true);
      //   setErrorMsg('Solo Visa o Mastercard')
      // }
      else {
        setError(false);
        setErrorParent(false);
        setErrorMsg(' ');
      }
    }
  }, [card])

  const onBlur = () => {
    if (typed) {
      if (card === '') {
        setError(true);
        setErrorParent(true);
        setErrorMsg('El número no debe estar vacío')
      } else if (card.substring(0, 1) === '5' && card.length !== 16) { //MasterCard
        setError(true);
        setErrorParent(true);
        setErrorMsg('Cantidad de dígitos incorrecta')
      } else if (card.substring(0, 1) === '4' && card.length !== 16 && card.length !== 13) { //Visa
        setError(true);
        setErrorParent(true);
        setErrorMsg('Cantidad de dígitos incorrecta')
      }
      // else if (card.substring(0, 1) !== '4' && card.substring(0, 1) !== '5') {
      //   setError(true);
      //   setErrorParent(true);
      //   setErrorMsg('Solo Visa o Mastercard')
      // }
       else {
        setError(false);
        setErrorParent(false);
        setErrorMsg(' ');
      }
    }
  }

  return (
    <Form.Group as={Col} controlId="test1">
      <Form.Label>Número de tarjeta</Form.Label>
      <Form.Control required type="input" placeholder="Ingrese..."
        style={{ borderLeft: '10px solid #00295E' }}
        ref={inputCard}
        onChange={() => { handleChange(); setTyped(true) }}
        onBlur={onBlur} />
      <Form.Text style={{ color: 'red' }}>
        {errorMsg ?? ''}
      </Form.Text>
    </Form.Group>
  )
}
