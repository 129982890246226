import React, { useState, useEffect } from 'react'
import {Form, InputGroup, } from 'react-bootstrap'
import "../../assets/styles/estadoCuenta.css"
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

function TelefonoSolicitudCliente({clientePais,setValue,setFlag,setPaisSeleccionado}) {

  const [codigo,setCodigo] = useState("")
  const [invalid,setInvalid] = useState(false)
  const [valueTemp,setValueTemp] = useState("")

  const codigoTelefonoPais = () =>{
    switch (clientePais) {
        case 'GT':
          setCodigo("+502")
          break;
        case 'DO':
          setCodigo("+1")
          break;
        case 'SV':
          setCodigo("+503")
          break;
        case 'HN':
          setCodigo("+504")
          break;
        case 'CR':
          setCodigo("+506")
          break;
        case 'NI':
          setCodigo("+505")
          break;
        case 'PA':
          setCodigo("+507")
          break;
        default:
          setCodigo("")
          break;
    }
  }

  const validacion = (telefono,country)=>{
    // const telefono = event.target.value
    setValueTemp(telefono)

    // var regexTelefono = new RegExp("^[0-9]{5,}$")
  
    // if (regexTelefono.test(telefono) ){
    //   setInvalid(false)
    //   setFlag(false)
    // }else{
    //   setInvalid(true)
    //   setFlag(true)
    // }

    if(telefono.length < 7){
      setFlag(true)
    }else{
      setFlag(false)
    }
    setPaisSeleccionado(country)

    setValue("+" + telefono)
    // console.log("Entri",country,telefono)
  }
  
  useEffect(() => {
    codigoTelefonoPais()
  }, [clientePais])

 
  return (
    <>
      <PhoneInput
        // onlyCountries={['gr', 'fr', 'us']}
        regions={['central-america', 'carribean']}
        country={clientePais.toLowerCase()}
        value={valueTemp}
        onChange={validacion}
      />
        {/* <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Número de teléfono/Celular</Form.Label>
            <InputGroup className="mb-3">
                <InputGroup.Text id="basic-addon1">{codigo}</InputGroup.Text>
                    <Form.Control
                    placeholder="Digite su numero (sin espacios o guiones)"
                    aria-label="numero"
                    aria-describedby="basic-addon1"
                    isInvalid={invalid}
                    value={valueTemp}
                    onChange={validacion}
                    />
                    <Form.Control.Feedback type="invalid">
                        Numero inválido
                    </Form.Control.Feedback>
            </InputGroup>
        </Form.Group> */}
    </>
  )
}

export {TelefonoSolicitudCliente};
