import React from 'react';

import { Spinner } from 'react-bootstrap';

import * as BsIcons from 'react-icons/bs';
import * as MdIcons from 'react-icons/md';

const LoadingBlur = ({savingChangesDb, showDbSuccessMessage = false, showDbErrorMessage = false, fullScreen = true}) => {
  const fs = fullScreen ? {} : {position: 'absolute'}
  return <div 
      className={!savingChangesDb ? 'loading-blur inactive': 'loading-blur'}
      style={{zIndex: savingChangesDb ? 10000 : -1, ...fs}}>
    <div style={{position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
      <div className={!showDbSuccessMessage ? 'db-success inactive' : 'db-success'}><BsIcons.BsFillCheckCircleFill size={50} color='green'/></div>
      <div className={!showDbErrorMessage ? 'db-error inactive' : 'db-error'}><MdIcons.MdError size={50} color='red'/></div>
      <div className={showDbSuccessMessage || showDbErrorMessage ? 'db-success inactive' : 'db-success'}><Spinner animation="grow"/></div>
    </div>
  </div>
}

export default LoadingBlur;